/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { LinearProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../components/Layout';
import MainWrapper from '../components/MainWrapper';
import PrivateRoute from '../helpers/PrivateRoute';

import LogIn from '../views/Account/LogIn';
import LogOut from '../views/Account/LogOut';
import Profile from '../views/Account/Profile';

import PosView from '../views/Pos';
import Clients from '../views/Clients';

import Expenses from '../views/Expenses';
import DetailExpense from '../views/Expenses/components/DetailExpense';

import Dashboard from '../views/Dashboard';
import Citas from '../views/Citas';

import ClientPage from '../views/ClientPage';
import ClientCall from '../views/ClientCall';
import Configuration from '../views/Clients/Configuration';

import AddCustomer from '../components/Cart/mobile/AddCustomer';
import EditCustomer from '../components/Cart/mobile/EditCustomer';
import AutoLogin from '../components/LogIn/AutoLogin';

import Activities from '../views/Activities';
import ActivityDetailDialog from '../views/Activities/components/ActivityDetail';
import AddActivity from '../views/Activities/components/AddActivity';
import Warehouse from '../views/Warehouse';
import Deliver from '../components/rkr2-components/deliver/OrderDeliver/Deliver';
import Receiver from '../components/rkr2-components/receiver/OrderReceive/Receiver';
import ExternalDeliver from '../components/rkr2-components/external-delivery/ExternalDeliver';
import TransferReceiver from '../components/rkr2-components/transfers/TransferReceiver';
import ScheduleExternalDeliver from '../components/rkr2-components/schedule-external-deliver';
import TransferProcessDetail from '../components/rkr2-components/transfers/TransferReceiverProducts/components/TransferProcessDetail';
import NotFoundRoute from '../components/rkr2-components/not-found/NotFoundRoute';
import MainPos from '../views/PosNew/MainPos';
import EntregaVenta from '../components/rkr2-components/wherehouse/components/entrega-venta/EntregaVenta';
import EntregaExterna from '../components/rkr2-components/wherehouse/components/entrega-externa/EntregaExterna';
import RecibirCompra from '../components/rkr2-components/wherehouse/components/recibir-compra/RecibirCompra';
import RecibirTransferencia from '../components/rkr2-components/wherehouse/components/recibir-transferencia/RecibirTransferencia';
import ProcesarTransferencia from '../components/rkr2-components/wherehouse/components/procesar-transferencia/ProcesarTransferencia';
import { WherehouseProvider } from '../components/rkr2-components/wherehouse/components/Wherehouse.context';


const AccountWrapped = () => (
  <>
    <Layout />
    <MainWrapper>
      <Profile />
    </MainWrapper>
  </>
);

const AccountRoutes = () => (
  <Switch>
    <Route path="/account/login" component={LogIn} />
    <Route path="/account/logout" component={LogOut} />
    <PrivateRoute path="/account/profile" component={AccountWrapped} />
    <PrivateRoute path="/account/profile/caja" component={AccountWrapped} />
    <PrivateRoute path="/account/profile/ventas" component={AccountWrapped} />
    <PrivateRoute path="/account/profile/general" component={AccountWrapped} />
  </Switch>
);
const ClientRoutes = () => (
  <Switch>
    <PrivateRoute exact path="/clients" component={Clients} />
    <PrivateRoute
      exact
      path="/clients/configuration"
      component={Configuration}
    />
    <PrivateRoute exact path="/clients/:crmid" component={ClientPage} />
    <PrivateRoute exact path="/clients/client/call" component={ClientCall} />
  </Switch>
);

const ExpensesRoutes = () => (
  <Switch>
    <PrivateRoute exact path="/expenses" component={Expenses} />
    <PrivateRoute exact path="/expenses/:crmid" component={DetailExpense} />
  </Switch>
);

const ActivityRoutes = () => (
  <Switch>
    <PrivateRoute exact path="/actividades" component={Activities} />
    <PrivateRoute exact path="/actividades/:id" component={ActivityDetailDialog} />
    <PrivateRoute exact path="/actividades/actividad/add" component={AddActivity} />
  </Switch>
);

const WarehouseRoutes = () => (
  <Switch>
    <WherehouseProvider>
      <PrivateRoute exact path="/almacenes" component={Warehouse} />
      <PrivateRoute exact path="/almacenes/entregar/:orderId" component={Deliver} />
      <PrivateRoute exact path="/almacenes/recibir/:orderId" component={Receiver} />
      <PrivateRoute exact path="/almacenes/entrega/externa/:orderId" component={ExternalDeliver} />
      <PrivateRoute exact path="/almacenes/programar/entrega/externa/:orderId" component={ScheduleExternalDeliver} />
      <PrivateRoute exact path="/almacenes/recibir/transferencia/:orderId" component={TransferReceiver} />
      <PrivateRoute exact path="/almacenes/procesar/transferencia/:orderId" component={TransferProcessDetail} />
      <PrivateRoute exact path="/almacenes/listar/entrega-venta" component={EntregaVenta} />
      <PrivateRoute exact path="/almacenes/listar/entrega-externa" component={EntregaExterna} />
      <PrivateRoute exact path="/almacenes/listar/recibir-compra" component={RecibirCompra} />
      <PrivateRoute exact path="/almacenes/listar/recibir-transferencia" component={RecibirTransferencia} />
      <PrivateRoute exact path="/almacenes/listar/procesar-transferencia" component={ProcesarTransferencia} />
    </WherehouseProvider>
  </Switch>
);

const MainRoutes = () => {
  const {
    listIsLoading, orderIsLoading, productIsLoading, cotizacionIsLoading,
  } = useSelector(state => state.loads);
  const authUser = useSelector(state => state.authUser);
  const {
    user: {
      config: {
        deshabilitar_venta,
        deshabilitar_clientes,
        deshabilitar_notas_de_compra,
        deshabilitar_citas,
        deshabilitar_actividades,
        deshabilitar_almacenes,
      },
    },
  } = authUser;

  return (
    <>
      <Layout />
      {
        (listIsLoading || orderIsLoading || productIsLoading || cotizacionIsLoading) && (
          <LinearProgress />
        )
      }
      <MainWrapper>
        <Switch>
          <Redirect exact from="/" to="/pos" />
          <PrivateRoute exact path="/tablero" component={Dashboard} />
          {
            deshabilitar_citas !== '1' && (
              <PrivateRoute exact path="/citas" component={Citas} />
            )
          }
          <PrivateRoute path="/addCustomer" component={AddCustomer} />
          <PrivateRoute path="/editCustomer" component={EditCustomer} />
          {
            deshabilitar_venta !== '1' && (
              <PrivateRoute path="/pos" component={MainPos} />
            )
          }
          {
            deshabilitar_venta !== '1' && (
              <PrivateRoute exact path="/pos/caja" component={MainPos} />
            )
          }
          {
            deshabilitar_venta !== '1' && (
              <PrivateRoute exact path="/pos/ventas" component={MainPos} />)
          }
          {
            deshabilitar_venta !== '1' && (
              <PrivateRoute exact path="/pos/ventas/:id" component={MainPos} />)
          }
          {
            deshabilitar_venta !== '1' && (
              <PrivateRoute exact path="/pos/cotizaciones" component={MainPos} />
            )
          }
          {
            deshabilitar_venta !== '1' && (
              <PrivateRoute exact path="/pos/carro" component={MainPos} />
            )
          }
          {
            deshabilitar_venta !== '1' && (
              <PrivateRoute exact path="/pos/carro" component={MainPos} />
            )
          }
          {
            deshabilitar_venta !== '1' && (
              <PrivateRoute path="/user/profile" component={Profile} />
            )
          }

          {
            deshabilitar_clientes !== '1' && (
              <PrivateRoute path="/clients" component={ClientRoutes} />
            )
          }
          {
            deshabilitar_notas_de_compra !== '1' && (
              <PrivateRoute path="/expenses" component={ExpensesRoutes} />
            )
          }
          {
            deshabilitar_actividades !== '1' && (
              <PrivateRoute path="/actividades" component={ActivityRoutes} />
            )
          }
          {
            deshabilitar_almacenes !== '1' && (
              <PrivateRoute path="/almacenes" component={WarehouseRoutes} />
            )
          }
          <Route path="/auto-login/:token" component={AutoLogin} />
        </Switch>
      </MainWrapper>
    </>
  ); };

const Router = () => (
  <Switch>
    <Route path="/account" component={AccountRoutes} />
    <Route path="/" component={MainRoutes} />
    <Route path="*" component={NotFoundRoute} />
  </Switch>
);

export default Router;
