/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */

/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { shouldCharge, shouldDeliver } from '../../../../helpers/order';
import {
  addItemsToTable,
  changeFieldValue,
  chnageComment,
  clearTable,
  selectEmptyToTable,
  selectToDelivered,
  selectToRefunded,
} from '../../../../redux/actions/tableActions';
import { getOrderPdf } from '../../../../redux/actions/posActions';
import { closeModal, openModal } from '../../../../redux/actions/modalActions';
import { setActivityToOrder } from '../../../../redux/actions/orderActions';
import CartCobrarBtn from '../cart-buttons/CartCobrarBtn';
import { deliverMoney } from '../../../../redux/actions/sellActions';
import DeliverV2 from '../../../Deliver/DeliverV2';
import CartRefundBtn from '../cart-buttons/CartRefundBtn';

const VentaContainer = ({ optionSelected, setOptionSelected }) => {
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);
  const modals = useSelector(state => state.modals);
  const loads = useSelector(state => state.loads);
  const tabs = useSelector(state => state.tabs);
  const table = useSelector(state => state.table);
  const authUser = useSelector(state => state.authUser);

  const { pos_botonventas, pos_deliver_out_stock } = authUser.user.config;
  const orderNo = cart.orderSelected && cart.orderSelected.order
    ? cart.orderSelected.order.salesorder_no || cart.orderSelected.order.label
    : '';
  const { orderSelected, totalProducts } = cart;
  const almacen = orderSelected.cartStateWarehouse || '';
  const productsInOrder = Boolean(totalProducts > 0);
  const mostrarHistorico = pos_botonventas !== undefined ? pos_botonventas : 'Ambas';
  const { enableAction } = table;
  const deliverOutStock = Boolean(pos_deliver_out_stock === '1');
  const withDiscount = Number.parseFloat(cart.total);
  const generalBalance = withDiscount - Number.parseFloat(cart.pagado);
  const orderExist = Boolean(Object.keys(orderSelected).length > 0);
  const orderStatus = orderSelected.status;

  useEffect(() => {
    if (tabs.posTypeTab === 'Historico' && !optionSelected) {
      if (
        shouldDeliver(almacen)
        && productsInOrder
        && (mostrarHistorico === 'Ambas' || mostrarHistorico === 'Entregar')
      ) {
        setOptionSelected('DeliverHistorico');
      }
      if (
        shouldCharge(orderSelected?.balance)
        && productsInOrder
        && (mostrarHistorico === 'Ambas' || mostrarHistorico === 'Cobrar')
      ) {
        setOptionSelected('CobrarHistorico');
      }
    }

    return () => {
      setOptionSelected('');
    };
  }, [orderSelected]);

  return (
    <div className="w-100 h-100 d-flex justify-content-around align-items-end flex-column">
      {orderSelected
        && shouldDeliver(almacen)
        && productsInOrder
        && orderStatus !== 'Cancelado'
        && (mostrarHistorico === 'Ambas' || mostrarHistorico === 'Entregar')
        && optionSelected === 'DeliverHistorico' && (
          <div className="w-100 h-100 d-flex align-items-end px-1">
            <DeliverV2
              table={table}
              loads={loads}
              authUser={authUser}
              dispatchSelectEmpty={() => dispatch(selectEmptyToTable())}
              dispatchChangeComment={comment => dispatch(chnageComment(comment))
              }
              dispatchChangeFieldValue={obj => dispatch(changeFieldValue(obj))
              }
              dispatchSelectToRefunded={() => dispatch(selectToRefunded())}
              dispatchSelectToDelivered={() => dispatch(selectToDelivered())}
              deliverEnableAction={Boolean(enableAction || deliverOutStock)}
              getOrderDocument={data => dispatch(getOrderPdf(data))}
              isOpen={Boolean(modals.deliverModalIsOpen)}
              onOpen={() => {
                dispatch(openModal('deliver'));
                dispatch(addItemsToTable('deliver'));
              }}
              onClose={() => {
                dispatch(closeModal('deliver'));
                dispatch(clearTable());
              }}
              isLoading={Boolean(loads.orderIsLoading)}
              onSave={() => {
                dispatch(
                  setActivityToOrder(cart.orderSelected.crmid, 'Entregar'),
                );
              }}
              disabled={
                cart.totalProducts < 1 || orderSelected.status === 'Cancelado'
              }
              title={`Entregar a cliente de la Orden: ${orderNo}`}
            />
          </div>
      )}
      {shouldCharge(orderSelected?.balance)
        && productsInOrder
        && (mostrarHistorico === 'Ambas' || mostrarHistorico === 'Cobrar')
        && optionSelected === 'CobrarHistorico'
        && orderStatus !== 'Cancelado' && (
          <div className="w-100 h-100 d-flex align-items-end px-1">
            <CartCobrarBtn
              title="Cobrar"
              modalTitle={`Cobrar Orden: ${
                orderSelected?.order.customerno
                || orderSelected?.order.salesorder_no
              }`}
              totalToPay={
                cart.orderSelected.order ? Number.parseFloat(generalBalance) : 0
              }
              onSave={(checkOut) => {
                const { payments } = checkOut;
                const orderId = cart.orderSelected.crmid;
                dispatch(deliverMoney(payments, orderId));
              }}
            />
          </div>
      )}

      {/* Estan comentados pq tienen display none en el proyecto original */}
      {/* {shouldRefoundMoney(orderSelected?.balance, orderSelected?.total) && optionSelected === 'DevolverDinero' && (
        <CartCobrarRefundBtn />
      )}
      {shouldRefoundMoney(orderSelected?.balance, orderSelected?.total) && orderExist && optionSelected === 'DevolverProductos' && (
        <CartRefundBtn />
      )} */}
      <CartRefundBtn />
    </div>
  );
};

VentaContainer.propTypes = {
  optionSelected: PropTypes.string.isRequired,
  setOptionSelected: PropTypes.func.isRequired,
};

export default VentaContainer;
