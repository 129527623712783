/* eslint-disable react/prop-types */
import { faShoppingBasket, faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';

const LayoutComprasMenu = ({ classes, closeDashboardMenu }) => {
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  return (
    <List dense component="div" style={{ backgroundColor: '#fff', paddingLeft: 10 }}>
      <ListItem button onClick={() => setOpen(!open)}>
        <ListItemIcon>
          <FontAwesomeIcon style={{ fontSize: 20 }} icon={faShoppingBasket} />
        </ListItemIcon>
        <ListItemText inset primary="Compras" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      {open && <Divider />}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense component="div" style={{ backgroundColor: '#fff', paddingLeft: 10 }}>
          <ListItem
            button
            className={classes.nested}
            onClick={() => {
              closeDashboardMenu();
              history.push('/almacenes/listar/recibir-compra');
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon style={{ fontSize: 20 }} icon={faSignInAlt} />
            </ListItemIcon>
            <ListItemText inset primary="Recibir compra" />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={() => {
              closeDashboardMenu();
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon style={{ fontSize: 20 }} icon={faSignOutAlt} />
            </ListItemIcon>
            <ListItemText inset primary="Devolución compra" />
          </ListItem>
        </List>
      </Collapse>
    </List>
  );
};

export default LayoutComprasMenu;
