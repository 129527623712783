/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  Paper,
  Tooltip,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { formatMoney } from '../../../helpers/tools';
import FlexMenu from '../headless-menu/FlexMenu';

const CartSellMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedCustomer = useSelector(
    state => state.customer.selectedCustomer,
  );
  const tabs = useSelector(state => state.tabs);

  useEffect(() => {
    const sessionCustomer = sessionStorage.getItem('customer_cxc_vencido_id');
    if (
      selectedCustomer?.cxc_vencido
      && parseFloat(selectedCustomer?.cxc_vencido) > 0
      && tabs.posTypeTab === 'Vender'
      && sessionCustomer !== selectedCustomer?.crmid
    ) {
      sessionStorage.setItem('customer_cxc_vencido_id', selectedCustomer?.crmid);
      setIsOpen(true);
    }
  }, [selectedCustomer, tabs]);

  return (
    <>
      <FlexMenu>
        <FlexMenu.Trigger
          as={IconButton}
          aria-label="Menu"
          style={{
            borderRadius: '5px',
            height: 40,
            with: 40,
            border: '1px solid #b8b8b8',
            backgroundColor: sessionStorage.getItem('customer_cxc_vencido_id') === selectedCustomer?.crmid ? 'red' : 'transparent',
            color: sessionStorage.getItem('customer_cxc_vencido_id') === selectedCustomer?.crmid ? 'white' : 'inherit',
          }}
        >
          <Tooltip title={sessionStorage.getItem('customer_cxc_vencido_id') === selectedCustomer?.crmid ? 'Cliente con saldo pendiente por cobrar vencido' : 'Información del cliente'}>
            <FontAwesomeIcon
              icon={faInfo}
              style={{ fontSize: '16px', width: 16 }}
            />
          </Tooltip>
        </FlexMenu.Trigger>
        {(selectedCustomer?.siccode
          || parseFloat(selectedCustomer?.puntos_asignados) > 0
          || (parseFloat(selectedCustomer.credito_d) > 0
            && parseFloat(selectedCustomer?.credito_m) > 0)
          || parseFloat(selectedCustomer?.cxc) > 0
          || parseFloat(selectedCustomer?.ndc_aplicadas) > 0
          || parseFloat(selectedCustomer?.xamortizar) > 0) && (
          <FlexMenu.OptionList as={Paper}>
            <List component="nav">
              {selectedCustomer && selectedCustomer?.siccode && (
                <ListItem
                  disableRipple
                  disableTouchRipple
                  style={{ height: '100%', cursor: 'default' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginBottom: 2,
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <span style={{ marginRight: 5, fontSize: 14 }}>RFC:</span>
                      <span style={{ fontWeight: 300, fontSize: 'medium' }}>
                        {selectedCustomer?.siccode || '-'}
                      </span>
                    </div>
                  </div>
                </ListItem>
              )}
              {selectedCustomer?.puntos_asignados
                && parseFloat(selectedCustomer?.puntos_asignados) > 0 && (
                  <>
                    <Divider />
                    <ListItem
                      disableRipple
                      disableTouchRipple
                      style={{ height: '100%', cursor: 'default' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: 2,
                        }}
                      >
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <span style={{ fontWeight: 300, fontSize: 'medium' }}>
                            Puntos
                          </span>
                          <span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                            <strong>Asignado:</strong>
                            {' '}
                            {formatMoney(selectedCustomer?.puntos_asignados)
                              || 0}
                          </span>
                          <span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                            <strong>Saldo:</strong>
                            {' '}
                            {formatMoney(selectedCustomer?.puntos_saldo) || 0}
                          </span>
                          <span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                            <strong>Usados:</strong>
                            {' '}
                            {formatMoney(selectedCustomer?.puntos_usados) || 0}
                          </span>
                        </div>
                      </div>
                    </ListItem>
                  </>
                )}
              {/* Créditos */}
              {selectedCustomer
                && parseFloat(selectedCustomer.credito_d) > 0
                && parseFloat(selectedCustomer?.credito_m) > 0 && (
                <>
                  <Divider />
                  <ListItem
                    disableRipple
                    disableTouchRipple
                    style={{ height: '100%', cursor: 'default' }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: 2,
                      }}
                    >
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <span style={{ fontWeight: 300, fontSize: 'medium' }}>
                          Crédito
                        </span>
                        <span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                          <strong>Limite de crédito:</strong>
                          {' '}
                          {formatMoney(selectedCustomer?.credito_m)}
                        </span>
                        <span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                          <strong>Dias de crédito:</strong>
                          {' '}
                          {selectedCustomer?.credito_d || 0}
                        </span>
                        <span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                          <strong>Deuda mas antigua</strong>
                          {' '}
                          {selectedCustomer?.fecha_deuda}
                        </span>
                        <span style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                          <strong>Crédito disponible:</strong>
                          {' '}
                          {formatMoney(selectedCustomer?.saldo_credito) || 0}
                        </span>
                      </div>
                    </div>
                  </ListItem>
                </>
              )}
              {/* Saldos */}
              {selectedCustomer
                && (parseFloat(selectedCustomer?.cxc) > 0
                  || parseFloat(selectedCustomer?.ndc_aplicadas) > 0
                  || parseFloat(selectedCustomer?.xamortizar) > 0) && (
                  <>
                  <Divider />
                  <ListItem
                      disableRipple
                      disableTouchRipple
                      style={{ height: '100%', cursor: 'default' }}
                    >
                      <div
                      style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: 2,
                        }}
                    >
                      <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <span style={{ fontWeight: 300, fontSize: 'large' }}>
                          Saldo
                          </span>
                          {parseFloat(selectedCustomer?.cxc) > 0 && (
                          <span
                            style={{ fontSize: 14, whiteSpace: 'nowrap' }}
                          >
                            <strong>Por Cobrar:</strong>
                            {' '}
                            {formatMoney(selectedCustomer?.cxc) || 0}
                          </span>
                        )}
                          {parseFloat(selectedCustomer?.cxc_vencido) > 0 && (
                          <span
                            style={{ fontSize: 14, whiteSpace: 'nowrap' }}
                          >
                            <strong>Por Cobrar Vencido:</strong>
                            {' '}
                            {formatMoney(selectedCustomer?.cxc_vencido) || 0}
                          </span>
                        )}
                          {selectedCustomer
                            && parseFloat(selectedCustomer.ndc_aplicadas) > 0 && (
                              <span
                                style={{ fontSize: 14, whiteSpace: 'nowrap' }}
                              >
                                <strong>Notas de crédito:</strong>
                                {' '}
                                {formatMoney(selectedCustomer?.ndc_aplicadas)
                                  || 0}
                              </span>
                        )}
                          {selectedCustomer
                            && parseFloat(selectedCustomer.xamortizar) > 0 && (
                              <span
                                style={{ fontSize: 14, whiteSpace: 'nowrap' }}
                              >
                                <strong>Anticipos a favor:</strong>
                                {' '}
                                {formatMoney(selectedCustomer.xamortizar) || 0}
                              </span>
                        )}
                        </div>
                    </div>
                    </ListItem>
                </>
              )}
            </List>
          </FlexMenu.OptionList>
        )}
      </FlexMenu>

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Por Cobrar Vencido</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            El cliente tiene un saldo pendiente por cobrar vencido, desea
            continuar con la venta?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)} color="primary" autoFocus>
            Entendido
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CartSellMenu;
