import React, { useEffect, useState } from 'react';
import {
  Button, createMuiTheme, LinearProgress, MuiThemeProvider, Paper,
} from '@material-ui/core';
import { red, orange } from '@material-ui/core/colors';
import OrderAPI from '../../../../api/OrderAPI';
import { useSelector } from 'react-redux';

const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: orange[500],
    },
    secondary: red,
  },
  typography: {
    useNextVariants: true,
  },
});

const ListAvailables = ({ moduleId, selectedList, onSelect }) => {
  const orderApi = new OrderAPI();
  const [loading, setLoading] = useState(false);
  const [availableList, setAvailableList] = useState([]);
  const [listSelected, setListSelected] = useState(selectedList);
  const authUser = useSelector(state => state.authUser.user);
  const { config: { store } } = authUser;

  const renderButton = (item) => {
    if (item.records >= 1 && item.records <= 20) {
      return (
        <MuiThemeProvider theme={customTheme}>
          <Button
            variant={
                    listSelected?.cvid === item?.cvid ? 'contained' : 'outlined'
                  }
            color="primary"
            key={item.cvid}
            onClick={() => {
              setListSelected(item);
              onSelect(item);
            }}
          >
            {item.records}
            {' '}
            |
            {' '}
            {item.viewname}
          </Button>
        </MuiThemeProvider>
      );
    }

    if (item.records > 20) {
      return (
        <MuiThemeProvider theme={customTheme}>
          <Button
            variant={
                    listSelected?.cvid === item?.cvid ? 'contained' : 'outlined'
                  }
            color="secondary"
            key={item.cvid}
            onClick={() => {
              setListSelected(item);
              onSelect(item);
            }}
          >
            {item.records}
            {' '}
            |
            {' '}
            {item.viewname}
          </Button>
        </MuiThemeProvider>
      );
    }
    return (
      <Button
        variant={
              listSelected?.cvid === item?.cvid ? 'contained' : 'outlined'
            }
        color="primary"
        key={item.cvid}
        onClick={() => {
          setListSelected(item);
          onSelect(item);
        }}
      >
        {item.records}
        {' '}
        |
        {' '}
        {item.viewname}
      </Button>
    );
  };

  const handleListChange = (item) => {
    let listSelectedParsed = null;

    if (moduleId === 1) {
      const entregarVentaListSelected = sessionStorage.getItem('entregarVentaListSelected');
      listSelectedParsed = JSON.parse(entregarVentaListSelected);
      if (listSelectedParsed && listSelectedParsed.cvid) {
        setListSelected(listSelectedParsed);
        onSelect(listSelectedParsed);
        return;
      }
    }

    if (moduleId === 2) {
      const entregaExternaListSelected = sessionStorage.getItem('entregaExternaListSelected');
      listSelectedParsed = JSON.parse(entregaExternaListSelected);
      if (listSelectedParsed && listSelectedParsed.cvid) {
        setListSelected(listSelectedParsed);
        onSelect(listSelectedParsed);
        return;
      }
    }

    if (moduleId === 3) {
      const recibirCompraListSelected = sessionStorage.getItem('recibirCompraListSelected');
      listSelectedParsed = JSON.parse(recibirCompraListSelected);
      if (listSelectedParsed && listSelectedParsed.cvid) {
        setListSelected(listSelectedParsed);
        onSelect(listSelectedParsed);
        return;
      }
    }

    if (moduleId === 4) {
      const recibirTransListSelected = sessionStorage.getItem('recibirTransListSelected');
      listSelectedParsed = JSON.parse(recibirTransListSelected);
      if (listSelectedParsed && listSelectedParsed.cvid) {
        setListSelected(listSelectedParsed);
        onSelect(listSelectedParsed);
        return;
      }
    }

    if (moduleId === 5) {
      const procesarTransListSelected = sessionStorage.getItem('procesarTransListSelected');
      listSelectedParsed = JSON.parse(procesarTransListSelected);
      if (listSelectedParsed && listSelectedParsed.cvid) {
        setListSelected(listSelectedParsed);
        onSelect(listSelectedParsed);
        return;
      }
    }

    setListSelected(item);
    onSelect(item);
  };

  const loadAvailableList = async () => {
    setLoading(true);
    const response = await orderApi.getWherehouseList(moduleId);
    const { success, result } = response;
    if (success && result.data.length > 0) {
      setAvailableList(result.data);
      if (!result.default) {
        handleListChange(result.data[0]);
      } else {
        const defaultList = result.data.find(item => item.cvid === result.default);
        handleListChange(defaultList);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    loadAvailableList();
  }, [store]);

  return (
    <Paper style={{ marginBottom: '16px' }}>
      {
        loading && <LinearProgress />
      }
      {
        !loading && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '5px',
              overflowX: 'auto',
              padding: '8px 16px',
            }}
          >
            {availableList
          && availableList.length > 0
              ? availableList.map(item => (
                renderButton(item)
              ))
              : <div>{loading ? <LinearProgress /> : 'No hay listas disponibles'}</div>
          }
          </div>
        )}
    </Paper>
  );
};

export default ListAvailables;
