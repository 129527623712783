/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lonely-if */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-cycle */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
/* Device */
import { isMobile } from 'react-device-detect';

/* Components */
import { v4 as uuidv4 } from 'uuid';
import { CircularProgress } from '@material-ui/core';
import AmountPayable from './components/AmountPayable';
import AmountCustomer from './components/AmountCustomer';
import AmountRegistry from './components/AmountRegistry';
import AmountTendered from './components/AmountTendered';
import AmountCheckOut from './components/AmountCheckOut';
import MethodCheckOut from './components/MethodCheckOut';
import CartCurrencyMessage from '../../../Cart/components/CartCurrencyMessage';
import BankCheckOut from './components/BankCheckOut';
import ClientAPI from '../../../../api/ClientAPI';
import SellAPI from '../../../../api/SellAPI';

const CheckOutConstructor = ({
  cart,
  customer,
  defaultOrderStatus,
  dispatchEditOrder,
  handleMakeBill,
  isLoading,
  makeBill,
  onSave,
  orderStatuses,
  paymentTypes,
  posCheckoutBill,
  totalToPay,
}) => {
  // Define a ref to hold the timeout ID
  const timeoutIdRef = React.useRef();
  const customerInStore = useSelector(state => state.customer);
  const authUser = useSelector(state => state.authUser);
  const {
    user: { currencies, metodos_pagos: metodoPagos, config },
  } = authUser;
  const { selectedCustomer } = customerInStore;
  const [isEvoLoading, setIsEvoLoading] = useState(false);

  const [rewardsPoints, setRewardsPoints] = useState(null);
  const [rewardsMethod, setRewardsMethod] = useState(null);

  // Order Selected
  const { orderSelected } = cart;
  const isOrder = Boolean(Object.keys(orderSelected).length > 0);

  /* new */
  const [payIndex, setPayIndex] = React.useState(0);

  /* Payable State */
  const inputEl = React.useRef(null);
  const [charge, setCharge] = React.useState(0);
  const [payable, setPayable] = React.useState('');

  /* Registry State */
  const [payments, setPayments] = React.useState([]);

  /* Registry Tendered */
  const [paidOut, setPaidOut] = React.useState(0);

  /* Registry Tendered */
  const [statusOrder, setStatusOrder] = React.useState(defaultOrderStatus);
  const [statusIndex, SetStatusIndex] = React.useState(
    orderStatuses.findIndex(x => x.id === statusOrder),
  );

  /* Payment selected */
  const [payment, setPayment] = React.useState(null);

  /* Bank selected */
  const [bank, setBank] = React.useState(null);

  function isLetter(c) {
    return c.toLowerCase() !== c.toUpperCase();
  }

  const setPayableValue = (val) => {
    let value = val;
    if (!isLetter(value) && !Number.isNaN(value)) {
      value = value.replace(/[$_'_,]/g, '');
      value = Number(value).toString();
      let newValue = Number.parseFloat(value);
      if (value === '.') {
        newValue = 0.0;
      }
      if (value === '') {
        // @ts-ignore
        setPayable(newValue);
        setCharge(newValue);
        // @ts-ignore
        newValue = '';
      }
      // @ts-ignore
      setPayable(newValue);
      setCharge(newValue);
    }
  };

  const changePayable = () => {
    if (inputEl) {
      const { value } = inputEl.current;

      if (
        bank?.tcyb_tipo === 'Puntos Recompensa'
        && payment.method === 'LBL_PUNTOS_RECOMPENSA'
      ) {
        const parseAmount = parseFloat(value.replace('$', ''));
        if (parseAmount > rewardsPoints.puntos_saldo) {
          toast.error('No tienes puntos suficientes para cubrir ese monto');
          inputEl.current.value = rewardsPoints.puntos_saldo;
          return;
        }
      }
      setPayableValue(value);
    }
  };

  /* General Functions */

  const resetWorkFlowToCheckout = () => {
    setCharge(0);
    setPayable('');
    if (inputEl) {
      inputEl.current.value = '';
      // @ts-ignore
      inputEl.current.focus();
    }
  };

  /* Payable Functions */
  const onBlurCharge = () => {
    const newCharge = Number.parseFloat(payable);
    // @ts-ignore
    setPayable(newCharge);
  };

  const formatPaymentTypes = () => {
    let arr = [];
    paymentTypes.forEach((type, index) => {
      const { body } = type;
      if (body) {
        const cbSelected = body.filter(account => account.select)[0];
        const cb = { ...cbSelected, id: type.method, index };
        arr = [...arr, cb];
      }
    });
    return arr;
  };

  const handleSetPayIndex = (index) => {
    setPayIndex(index);
  };

  const getCurrencyConvertRate = () => {
    try {
      let convertRate = 1;
      let activeCurrencyId;
      if (isOrder) {
        const { order } = orderSelected;
        const orderCUrrencyId = order.currency_id.split('x').pop();
        activeCurrencyId = orderCUrrencyId;
      } else {
        activeCurrencyId = localStorage.getItem('currencyId');
      }
      const activeCurrency = currencies.find(c => c.id === activeCurrencyId);

      // If not currency_id exist
      if (!bank?.currency_id || bank?.currency_id === activeCurrencyId) {
        return convertRate;
      }

      const paymentMethodSelectedCurrency = currencies.find(
        c => c.id === bank?.currency_id,
      );

      if (activeCurrency && paymentMethodSelectedCurrency) {
        convertRate = Number.parseFloat(paymentMethodSelectedCurrency.conversion_rate)
          / Number.parseFloat(activeCurrency.conversion_rate);
      }

      return convertRate;
    } catch (error) {
      toast.error(
        'Error calculando la conversion de moneda, asegurese de haber seleccionado el metodo de pago y un banco o caja',
      );
    }
  };

  const convertMoney = amount => amount * getCurrencyConvertRate();

  const getConvertRate = (fromCurrencyId, toCurrencyId) => {
    try {
      // Get actual currency
      const fromCurrency = currencies.find(p => p.id == fromCurrencyId);
      // Get currency to convert
      const toCurrency = currencies.find(p => p.id == toCurrencyId);

      return (
        Number.parseFloat(fromCurrency.conversion_rate)
        / Number.parseFloat(toCurrency?.conversion_rate || 1)
      );
    } catch (error) {
      toast.error('Error calculando la conversion de moneda');
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  // Calculate total of payments in only one currency, in this case the currency of selected oay method
  const getPaymentsTotalInOneCurrency = () => {
    try {
      let paymentSavedTotal = 0;
      if (payments.length) {
        // eslint-disable-next-line no-restricted-syntax
        for (const pay of payments) {
          // Find select pay method currency

          const conversionRate = getConvertRate(
            pay.currency_id,
            bank?.currency_id,
          );
          paymentSavedTotal += pay.monto / conversionRate;
        }
      }
      return paymentSavedTotal;
    } catch (error) {
      toast.warn('Ocurrio un error intentando convertir la moneda');

      return null;
    }
  };

  const getRewardPoints = async () => {
    await setRewardsPoints(null);
    await setRewardsMethod(null);

    const clientApi = new ClientAPI();
    const response = await clientApi.getRewardPoints(selectedCustomer.crmid);
    if (
      response.success
      && response.result.puntos_asignados > 0
      && response.result.puntos_saldo > 0
    ) {
      const rewardMethod = {
        method: 'LBL_PUNTOS_RECOMPENSA',
        key: metodoPagos.length,
        body: [
          {
            crmid: selectedCustomer.crmid, // Selected Customer crmid
            currency_code: response.currency_code,
            currency_id: response.currency_id,
            entity_label: 'Puntos Recompensa',
            referencia: 'Puntos Recompensa',
            tcyb_tipo: 'Puntos Recompensa',
          },
        ],
      };
      setRewardsMethod(rewardMethod);
      setRewardsPoints(response.result);
    }
  };

  React.useEffect(() => {
    if (selectedCustomer) {
      setPayments([]);
      getRewardPoints();
    }
  }, [selectedCustomer]);

  /* Once mounted */
  React.useEffect(() => {
    const convertTotalToPay = convertMoney(totalToPay);
    // @ts-ignore
    const newCharge = Number.parseFloat(convertTotalToPay);
    setCharge(newCharge);
    setPayable(convertTotalToPay);
    const metaInput = document.querySelector('#payable_dollar');
    if (metaInput) {
      if (newCharge) {
        // @ts-ignore
        metaInput.value = newCharge;
        setTimeout(async () => {
          // @ts-ignore
          // metaInput.select();
          setPayable(convertTotalToPay);
          await setCharge(newCharge);
        }, 100);
      } else {
        // @ts-ignore
        // metaInput.focus();
      }
    }
  }, [totalToPay]);

  /* Once payIndex change */
  React.useEffect(() => {
    // Calculate add payments
    const paymentSavedTotal = getPaymentsTotalInOneCurrency();
    const totalToPayConvert = convertMoney(totalToPay);
    const convertTotalToPay = totalToPayConvert - paymentSavedTotal;
    // @ts-ignore
    const newCharge = Number.parseFloat(convertTotalToPay);
    setCharge(newCharge);
    setPayable(convertTotalToPay);
    setPaidOut(paymentSavedTotal);
    const metaInput = document.querySelector('#payable_dollar');
    if (metaInput) {
      if (newCharge) {
        // @ts-ignore
        metaInput.value = newCharge;
        setTimeout(async () => {
          if (
            bank
            && bank.tcyb_tipo === 'Puntos Recompensa'
            && rewardsPoints
            // && rewardsPoints.puntos_saldo
          ) {
            inputEl.current.value = `${
              rewardsPoints.puntos_saldo > newCharge
                ? newCharge
                : rewardsPoints.puntos_saldo
            }`;
            setPayableValue(inputEl.current.value);
            localStorage.getItem('printMode') === 'true'
              ? null
              : metaInput.select();
            setPayable(rewardsPoints.puntos_saldo);
            // await setCharge(rewardsPoints.puntos_saldo);
          } else {
            // @ts-ignore
            localStorage.getItem('printMode') === 'true'
              ? null
              : metaInput.select();
            setPayable(convertTotalToPay);
            await setCharge(newCharge);
          }
        }, 100);
      } else {
        // @ts-ignore
        if (
          localStorage.getItem('printMode') === 'false'
          || !localStorage.getItem('printMode')
        ) {
          metaInput.focus();
        }
      }
    }
  }, [bank]);

  /* Method Functions */
  const newPaymentTypes = formatPaymentTypes();

  /* Registry Functions */

  const addPayment = (inputValue = null) => {
    try {
      // @ts-ignore
      const newMoney = inputValue
        ? Number.parseFloat(inputValue)
        : Number.parseFloat(charge);
      if (
        payment.method === 'LBL_PUNTOS_RECOMPENSA'
        && newMoney > rewardsPoints.puntos_saldo
      ) {
        toast.error('No tienes puntos suficientes para cubrir ese monto');
        return;
      }

      if (
        payment.method === 'LBL_PUNTOS_RECOMPENSA'
        && newMoney <= rewardsPoints.puntos_saldo
      ) {
        rewardsPoints.puntos_saldo -= newMoney;
      }

      let newPayment = {};
      let array = [];
      if (payment && newMoney > 0 && bank) {
        const exist = payments.find(
          x => x.metodo === payment.method && x.referencia === bank?.referencia,
        );
        if (exist && payment.method === 'Efectivo') {
          const paymentsMap = payments.map((x) => {
            const item = { ...x };
            if (
              item.metodo === exist.metodo
              && item.referencia === exist.referencia
            ) {
              item.monto += newMoney;
            }
            return item;
          });
          array = [...paymentsMap];
        } else {
          newPayment = {
            monto: newMoney,
            // @ts-ignore
            metodo: payment.method, // Metodo Ex: Efectivo, tarjeta credito
            // @ts-ignore
            tcybid: bank?.crmid, // Id de banco o caja
            referencia: bank?.referencia, // Banco ex: Santander, HSBC, Caja chica
            currency_id: bank?.currency_id, // Moneda
            // Add terminales
            terminales: bank?.terminales,
          };
          array = [...payments, newPayment];
        }
        if (newPaymentTypes.length > 0) {
          setPayments(array);
          resetWorkFlowToCheckout();
        }
      } else {
        toast.warn(
          'Asegurese de haber seleccionado un metodo de pago, una caja o banco y de haber agregado el monto que quiere adicionar',
        );
      }
    } catch (error) {
      toast.error('Error intentando agregar el pago');
    }
  };

  const removePayment = (index) => {
    const actualPayments = [...payments];
    if (payments[index].metodo === 'LBL_PUNTOS_RECOMPENSA') {
      rewardsPoints.puntos_saldo += payments[index].monto;
    }
    actualPayments.splice(index, 1);
    setPayments(actualPayments);
  };

  /* Tendered Functions */
  React.useEffect(() => {
    const totalPayOut = getPaymentsTotalInOneCurrency();
    setPaidOut(totalPayOut);
  }, [payments]);

  // Make get request
  async function makeGetRequest(transactionId, posId) {
    const storedTransactionId = localStorage.getItem('transactionId');
    const sellApi = new SellAPI();
    const realTransactionId = storedTransactionId || transactionId;
    const response = await sellApi.evoPaymentDetail(realTransactionId, posId);
    if (response.success) {
      if (
        response.result.SaleResponse?.ResponseCode === -1
        && response.result.SaleResponse?.ResponseMessage === 'APROBADA (00)'
      ) {
        toast.success('La transacción fue exitosa');
        // localStorage.removeItem('transactionId');
        return response.result;
      }

      if (
        response.result.SaleResponse?.ResponseCode === 99
        && response.result.SaleResponse?.ResponseMessage === 'Txn no completada'
      ) {
        toast.error('La transacción fallo o no fue atendida en tiempo');
        localStorage.removeItem('transactionId');
        return null;
      }

      if (
        response.result.SaleResponse?.ResponseCode === 35
        && response.result.SaleResponse?.ResponseMessage === 'Falla Emv, reintente'
      ) {
        toast.error('La transacción fallo, intenta de nuevo');
        localStorage.removeItem('transactionId');
        return null;
      }

      if (
        response.result.SaleResponse?.ResponseCode === 98
        && response.result.SaleResponse?.ResponseMessage === 'Txn cancelada por TH'
      ) {
        toast.error('La transacción fue cancelada en ala terminal');
        localStorage.removeItem('transactionId');
        return null;
      }
      await new Promise((resolve) => {
        timeoutIdRef.current = setTimeout(resolve, 3000);
      });
      return makeGetRequest(transactionId, posId);
    }
  }

  const handleMakeTerminalBill = async (terminalStore, pay) => {
    try {
      const existTransactionId = localStorage.getItem('transactionId');
      const sellApi = new SellAPI();
      const terminal = Object.values(terminalStore)[0];
      const url = `${terminal.url}/${terminal.config.posid}/sale`;
      const traceId = uuidv4();
      localStorage.setItem('traceId', traceId);
      let response;

      if (!existTransactionId) {
        response = await sellApi.evoPayment(
          terminal.config.posid,
          pay.monto,
          traceId,
        );

        if (response.success) {
          const { transactionId } = response.result;
          localStorage.setItem('transactionId', transactionId);
          const detail = await makeGetRequest(transactionId, terminal.config.posid);
          setIsEvoLoading(false);
          return detail;
        }
      } else {
        const detail = await makeGetRequest(existTransactionId, terminal.config.posid);
        setIsEvoLoading(false);
        return detail;
      }
    } catch (error) {
      toast.error('Ha ocurrido un error en el cobro con terminal, o no hay conexion con el api');
      localStorage.removeItem('transactionId');
      localStorage.removeItem('traceId');
      setIsEvoLoading(false);
      console.log(error);
      return null;
    }

    return null;
  };

  async function makeClipGetRequest(pinPadId) {
    const sellApi = new SellAPI();
    const response = await sellApi.clipPaymentDetail(pinPadId);
    if (response.result.status === 'APPROVED') {
      toast.success('La transacción fue exitosa');
      return response.result;
    }

    if (response.result.status === 'IN_PROCESS') {
      await new Promise((resolve) => {
        timeoutIdRef.current = setTimeout(resolve, 3000);
      });
      return makeClipGetRequest(pinPadId);
    }

    if (response.result.status === 'REJECTED') {
      toast.error('La transacción fue rechazada');
      return null;
    }

    if (response.result.status === 'CANCELED') {
      toast.error('La transacción fue cancelada');
      return null;
    }

    if (response.result.code === 'PINPAD_TERMINAL_TIMEOUT_EXCEPTION') {
      toast.error(response.result.message || 'La transacción no fue atendida en tiempo');
      return null;
    }

    return null;
  }

  const handleMakeClipBill = async (terminalStore, pay) => {
    try {
      const sellApi = new SellAPI();
      const terminal = Object.values(terminalStore)[0];
      const response = await sellApi.clipPayment(terminal.config.posid, pay.monto);
      // debugger;
      if (response.result.pinpad_request_id) {
        const detail = await makeClipGetRequest(response.result.pinpad_request_id);
        setIsEvoLoading(false);
        return detail;
      }
      if (!response.success) {
        if (response.result.code === 'INVALID_INPUT') {
          toast.error('El monto ingresado o el pin ingresado es invalido');
          setIsEvoLoading(false);
          return null;
        }
        toast.error('Ha ocurrido un error en el cobro con terminal, o no hay conexion con el api');
        setIsEvoLoading(false);
        return null;
      }
    } catch (error) {
      setIsEvoLoading(false);
      console.log(error);
      return null;
    }
  };

  /* Checkout Function (Submit) */

  const beforeSubmit = arr => new Promise((resolve, reject) => {
    let array = [...arr];
    const existEfectivo = Boolean(array.some(x => x.metodo === 'Efectivo'));
    // Total recibido en cash
    if (existEfectivo) {
      const onlyCash = arr.filter(x => x.metodo === 'Efectivo');
      const totalRecibido = onlyCash.reduce((acc, x) => acc + x.monto, 0);
      localStorage.setItem('recibido', totalRecibido);
    }
    const needFix = Boolean(paidOut + charge > convertMoney(totalToPay));

    if (needFix) {
      const balance = Math.abs(
        convertMoney(totalToPay) - (paidOut + charge),
      ).toFixed(2);
      if (existEfectivo) {
        array = [];
        array = arr.map((x) => {
          if (x.metodo === 'Efectivo') {
            if (x.monto >= balance) {
              x.monto = Math.abs(x.monto - balance);
            } else {
              toast.warn(
                'Atención: El suma del cambio supera a la cantidad en efectivo, revise las partidad agregadas',
              );
              localStorage.removeItem('recibido');
              reject();
            }
            // @ts-ignore
          }
          return x;
        });
        resolve({ payments: array, currentOrderStatus: statusOrder });
      } else {
        toast.info(
          'Atención: la suma de los métodos de pago elegidos debe ser exacta',
        );
        localStorage.removeItem('recibido');
        reject();
      }
    }
    resolve({ payments: array, currentOrderStatus: statusOrder });
  });

  const handleEvoPayment = async paymentList => new Promise(async (resolve, reject) => {
    const paymentArray = [...paymentList];
    for (const p of paymentArray) {
      if (p.referencia === 'Evo Payments') {
        const storeId = orderSelected && orderSelected.almid ? orderSelected.almid : config.store.crmid;
        const terminalStore = p.terminales[storeId];
        if (Object.keys(terminalStore).length > 0) {
          setIsEvoLoading(true);
          const response = await handleMakeTerminalBill(
            terminalStore,
            p,
          );
          p.transaction_response = JSON.stringify(response?.SaleResponse);
          p.transaction = response?.SaleResponse?.ARQC;
          p.description = JSON.stringify(response?.SaleResponse);
          p.traceId = localStorage.getItem('traceId');
          p.transactionId = localStorage.getItem('transactionId');

          // Remove traceId and transactionId from localStorage
          localStorage.removeItem('traceId');
          localStorage.removeItem('transactionId');
        } else {
          toast.error('No se ha seleccionado terminal de pago');
        }
      }
    }

    const failedPayment = paymentArray.some(
      x => x.referencia === 'Evo Payments' && !x.transaction_response,
    );
    if (failedPayment) {
      toast.error('Ha ocurrido un error en algun cobro con terminal');
      reject();
    }
    resolve(paymentArray);
  });

  const handleClipPayment = async paymentList => new Promise(async (resolve, reject) => {
    const paymentArray = [...paymentList];
    for (const p of paymentArray) {
      if (p.referencia === 'Clip') {
        const storeId = orderSelected && orderSelected.almid ? orderSelected.almid : config.store.crmid;
        const terminalStore = p.terminales[storeId];
        if (Object.keys(terminalStore).length > 0) {
          setIsEvoLoading(true);
          const response = await handleMakeClipBill(
            terminalStore,
            p,
          );
          p.transaction_response = response ? JSON.stringify(response, null, 2) : null;
        } else {
          toast.error('No se ha seleccionado terminal de pago');
        }
      }
    }

    const failedPayment = paymentArray.some(
      x => x.referencia === 'Clip' && (!x.transaction_response || x.transaction_response === 'null'),
    );
    if (failedPayment) {
      toast.error('Ha ocurrido un error en algun cobro con terminal Clip');
      reject();
    }
    resolve(paymentArray);
  });

  const onSubmit = async () => {
    if (!isLoading) {
      if (charge > 0) {
        if (charge > totalToPay) {
          // localStorage.setItem('recibido', charge);
          localStorage.setItem('cambio', charge - totalToPay);
        } else {
          localStorage.setItem('cambio', 0);
        }
        // @ts-ignore
        const newMoney = Number.parseFloat(charge);
        let newPayment = {};
        let array = [];
        if (payment && newMoney > 0 && bank) {
          const exist = payments.find(
            x => x.metodo === payment.method && x.referencia === bank?.referencia,
          );
          if (exist) {
            const paymentsMap = payments.map((x) => {
              const item = { ...x };
              if (
                item.metodo === exist.metodo
                && item.referencia === exist.referencia
              ) {
                item.monto += newMoney;
              }
              return item;
            });
            array = [...paymentsMap];
          } else {
            if (
              payment.method === 'LBL_PUNTOS_RECOMPENSA'
              && newMoney > rewardsPoints?.puntos_saldo
            ) {
              toast.error('No cuenta con puntos suficientes');
              return;
            }
            newPayment = {
              monto: newMoney,
              // @ts-ignore
              metodo: payment.method,
              // @ts-ignore
              tcybid: bank?.crmid,
              referencia: bank?.referencia,
              // Add terminales
              terminales: bank?.terminales,
            };
            array = [...payments, newPayment];
          }
          // Check if there is a payment with Evo or Clip
          const evoPayment = array.find(x => x.referencia === 'Evo Payments');
          const clipPayment = array.find(x => x.referencia === 'Clip');

          if (evoPayment) {
            array = (await handleEvoPayment(array)) || [];
          }
          if (clipPayment) {
            array = (await handleClipPayment(array)) || [];
          }

          if (newPaymentTypes.length > 0) {
            beforeSubmit(array).then(response => onSave(response));
          }
        } else {
          toast.warn('Error intentando cobrar la orden');
        }
      } else {
        let montoTotal = 0;
        payments.forEach((pay) => {
          montoTotal += pay.monto;
        });
        if (montoTotal === 0) {
          toast.error('Debes ingresar un monto para cobrar la orden');
          return;
        }
        if (montoTotal > totalToPay) {
          // localStorage.setItem('recibido', montoTotal);
          localStorage.setItem('cambio', montoTotal - totalToPay);
        } else {
          localStorage.setItem('cambio', 0);
        }

        // const formatPayments = (await handleEvoPayment(payments)) || [];

        beforeSubmit(payments).then(response => onSave(response));
      }
    } else {
      toast.warn(
        'Ya estamos procesando un cobro, favor de esperar a que termine',
      );
    }
  };

  const setStatusByIndex = (index) => {
    const newStatus = orderStatuses[index].id;
    setStatusOrder(newStatus);
    SetStatusIndex(index);
  };

  const selectPaymentMethod = (pay) => {
    setPayment(pay);
    try {
      if (pay.body && pay.body.length === 1) {
        setBank(pay.body[0]);
      } else {
        setBank(null);
      }
    } catch (error) {
      toast.error('Error seleccionando el banco o caja');
    }
  };

  const selectBank = async (banc) => {
    await setBank(null);
    await setBank(banc);
  };

  /* CSS */
  const mainCont = isMobile
    ? 'w-100 d-flex flex-column px-2 py-1 minHeightMobile'
    : 'w-100 d-flex flex-column';
  const inputMoney = paidOut + charge;

  function cancelarCobroTerminal() {
    setIsEvoLoading(false);
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
  }

  React.useEffect(
    () =>
      // No timeout is set here, but we still return a cleanup function
      // eslint-disable-next-line implicit-arrow-linebreak
      () => {
        // Clear the timeout
        if (timeoutIdRef.current) {
          clearTimeout(timeoutIdRef.current);
        }
        localStorage.removeItem('transactionId');
        localStorage.removeItem('traceId');
      },
    [],
  );

  return (
    <>
      {isEvoLoading && (
        <div
          className="loading-evo"
          style={{
            backgroundColor: '#3f3f3fcc',
            position: 'fixed',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9,
            gap: 10,
          }}
        >
          <CircularProgress />
          <div>
            <span style={{ fontSize: 18, color: 'white' }}>
              Esperando al cobro en terminal
            </span>
          </div>
          {/* <div>
            <Button variant="contained" onClick={cancelarCobroTerminal}>
              Cancelar cobro en terminal
            </Button>
          </div> */}
        </div>
      )}
      <div className={mainCont}>
        <AmountCustomer
          cart={cart}
          customer={customer}
          handleMakeBill={handleMakeBill}
          makeBill={makeBill}
          posCheckoutBill={posCheckoutBill}
          dispatchEditOrder={dispatchEditOrder}
        />
        {bank && <CartCurrencyMessage bank={bank} />}
        <MethodCheckOut
          payment={payment}
          selectPayment={pay => selectPaymentMethod(pay)}
          rewardsMethod={rewardsMethod}
        />
        {payment && (
          <BankCheckOut
            payment={payment}
            bank={bank}
            setBank={banc => selectBank(banc)}
            setPayIndex={handleSetPayIndex}
            rewardsPoints={rewardsPoints}
          />
        )}

        {payment && (bank || payments.length !== 0) && (
          <>
            <AmountPayable
              charge={charge}
              inputEl={inputEl}
              paidOut={paidOut}
              onSubmit={onSubmit}
              payIndex={payIndex}
              isLoading={isLoading}
              addPayment={addPayment}
              onBlurCharge={onBlurCharge}
              changePayable={changePayable}
              setPayIndex={handleSetPayIndex}
              newPaymentTypes={newPaymentTypes}
              totalToPay={Math.abs(convertMoney(totalToPay) - inputMoney)}
            />
            <AmountRegistry
              charge={charge}
              payments={payments}
              onSubmit={onSubmit}
              totalToPay={convertMoney(totalToPay)}
              addPayment={addPayment}
              removePayment={removePayment}
            />
            <AmountTendered
              charge={charge}
              paidOut={paidOut}
              totalToPay={convertMoney(totalToPay)}
            />
            {config.pos_permite_cobro_parcial
            && config.pos_permite_cobro_parcial === '1' ? (
              <AmountCheckOut
                onSubmit={onSubmit}
                statusIndex={statusIndex}
                statusOrder={statusOrder}
                orderStatuses={orderStatuses}
                enableSubmit={Boolean(charge + paidOut > 0)}
                setStatusOrder={setStatusOrder}
                SetStatusIndex={setStatusByIndex}
              />
              ) : config.pos_permite_cobro_parcial
              && config.pos_permite_cobro_parcial === '0'
              && Number(totalToPay.toFixed(2))
                < Number((paidOut + charge).toFixed(2)) ? ( // Add toFixed(2) to avoid arithmetic floating point error
                  <AmountCheckOut
                    onSubmit={onSubmit}
                    statusIndex={statusIndex}
                    statusOrder={statusOrder}
                    orderStatuses={orderStatuses}
                    enableSubmit={Boolean(charge + paidOut > 0)}
                    setStatusOrder={setStatusOrder}
                    SetStatusIndex={setStatusByIndex}
                  />
                ) : Number(
                  Math.abs(
                    Number(totalToPay.toFixed(2))
                    - Number((paidOut + charge).toFixed(2)),
                  ),
                ) > 0 ? null : (
                  <AmountCheckOut
                    onSubmit={onSubmit}
                    statusIndex={statusIndex}
                    statusOrder={statusOrder}
                    orderStatuses={orderStatuses}
                    enableSubmit={Boolean(charge + paidOut > 0)}
                    setStatusOrder={setStatusOrder}
                    SetStatusIndex={setStatusByIndex}
                  />
                  )}

            {/* Use this to debug prices
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <span>
                totalToPay:
                {Number(totalToPay.toFixed(2))}
              </span>
              <span>
                paidOut:
                {paidOut}
              </span>
              <span>
                charge:
                {charge}
              </span>
              <span>
                paidOut + charge:
                {Number((paidOut + charge).toFixed(2))}
              </span>
            </div> */}
          </>
        )}
      </div>
    </>
  );
};

CheckOutConstructor.propTypes = {
  cart: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  defaultOrderStatus: PropTypes.string.isRequired,
  handleMakeBill: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  makeBill: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  dispatchEditOrder: PropTypes.func.isRequired,
  orderStatuses: PropTypes.array.isRequired,
  paymentTypes: PropTypes.array.isRequired,
  posCheckoutBill: PropTypes.string.isRequired,
  totalToPay: PropTypes.number.isRequired,
};

CheckOutConstructor.defaultProps = {
  isLoading: false,
};

export default CheckOutConstructor;
