/* eslint-disable react/prop-types */
import React, {
  createContext, useContext, useReducer,
} from 'react';

export const WherehouseContext = createContext();
export const WherehouseDispatchContext = createContext();


const initialState = {
  entregaExterna: {
    orderBy: 'createdtime',
    sortorder: 'DESC',
  },
  entregaVenta: {
    orderBy: 'createdtime',
    sortorder: 'DESC',
  },
  procesarTraspaso: {
    orderBy: 'createdtime',
    sortorder: 'DESC',
  },
  recibirTraspaso: {
    orderBy: 'createdtime',
    sortorder: 'DESC',
  },
  recibirCompra: {
    orderBy: 'createdtime',
    sortorder: 'DESC',
  },
};

export const wherehouseActionTypes = {
  CLEAR_FILTERS: 'CLEAR_FILTERS',
  CLEAR_FILTERS_ALL: 'CLEAR_FILTERS_ALL',
  SET_ORDER_BY: 'SET_ORDER_BY',
  SET_SORT_ORDER: 'SET_SORT_ORDER',
  SET_ORDER_BY_AND_SORT_ORDER: 'SET_ORDER_BY_AND_SORT_ORDER',
};

const wherehouseReducer = (state, action) => {
  switch (action.type) {
    case wherehouseActionTypes.SET_ORDER_BY:
      return { ...state, [action.payload.module]: { ...state[action.payload.module], orderBy: action.payload.orderBy } };
    case wherehouseActionTypes.SET_SORT_ORDER:
      return { ...state, [action.payload.module]: { ...state[action.payload.module], sortorder: action.payload.sortorder } };
    case wherehouseActionTypes.SET_ORDER_BY_AND_SORT_ORDER:
      return { ...state, [action.payload.module]: { ...state[action.payload.module], orderBy: action.payload.orderBy, sortorder: action.payload.sortorder } };
    case wherehouseActionTypes.CLEAR_FILTERS:
      return { ...state, [action.payload.module]: { ...state[action.payload.module], orderBy: 'createdtime', sortorder: 'DESC' } };
    case wherehouseActionTypes.CLEAR_FILTERS_ALL:
      return initialState;
    default:
      return state;
  }
};

export function WherehouseProvider({ children }) {
  const [wherehousehouseState, dispatch] = useReducer(
    wherehouseReducer,
    initialState,
  );

  return (
    <WherehouseContext.Provider value={wherehousehouseState}>
      <WherehouseDispatchContext.Provider value={dispatch}>
        {children}
      </WherehouseDispatchContext.Provider>
    </WherehouseContext.Provider>
  );
}

export function useWherehouse() {
  return useContext(WherehouseContext);
}

export function useWherehouseDispatch() {
  return useContext(WherehouseDispatchContext);
}
