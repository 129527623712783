/* eslint-disable import/order */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  TableSortLabel,
} from '@material-ui/core';
import WhereHouseFilters from '../../wherehouse-filters';
import { useHistory } from 'react-router-dom';
import { Send, Share } from '@material-ui/icons';
import OrderStatusIconBtn from '../../../order-status-icon-button/OrderStatusIconBtn';
import OrderFdeIconBtn from '../../../order-status-icon-button/OrderFdeIconBtn';
import OrderMdaIconBtn from '../../../order-status-icon-button/OrderMdaIconBtn';
import ShareModal from '../../../../Share/components/ShareModal';
import appModules from '../../../../../constant/appModules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useWherehouse, useWherehouseDispatch, wherehouseActionTypes } from '../Wherehouse.context';

const EntregaExternaDataTable = ({
  headers = [], data = [], onSearch, loading = false,
}) => {
  const [openShare, setOpenShare] = React.useState(false);
  const [selectSale, setSelectSale] = React.useState(null);
  const dispatch = useWherehouseDispatch();
  const { entregaExterna } = useWherehouse();
  const statusKeys = [
    'sostatus',
    'quotestage',
    'estado_fde',
    'estado_mda',
  ];

  function handleUpdate(sortBy, order) {
    dispatch({
      type: wherehouseActionTypes.SET_ORDER_BY_AND_SORT_ORDER,
      payload: {
        module: 'entregaExterna',
        orderBy: sortBy,
        sortorder: order,
      },
    });
  }

  const ActionMenu = ({ order, handleSelectOrder }) => {
    const history = useHistory();

    const handleShare = async () => {
      await handleSelectOrder(order);
      setOpenShare(true);
    };

    const handleDeliver = () => {
      history.push(`/almacenes/entrega/externa/${order.crmid}`);
    };

    return (
      <div>
        <Tooltip title="Compartir" aria-label="Compartir">
          <IconButton
            aria-label="Compartir"
            aria-haspopup="true"
            onClick={handleShare}
          >
            <Share />
          </IconButton>
        </Tooltip>
        <Tooltip title="Entregar" aria-label="Entregar">
          <IconButton
            aria-label="Entregar"
            aria-haspopup="true"
            onClick={handleDeliver}
          >
            {/* <Send /> */}
            <FontAwesomeIcon icon={faSignOutAlt} />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  const handleShareClose = () => {
    setOpenShare(false);
  };

  const renderStatusIcon = (key, row) => {
    switch (key) {
      case 'sostatus':
        return (
          <OrderStatusIconBtn
            filter={{ name: 'sostatus', value: row[key] }}
            selectedValues={[row[key]]}
            updateStatusFn={() => {}}
            disabled
          />
        );
      case 'estado_fde':
        return (
          <OrderFdeIconBtn
            filter={{ name: 'estado_fde', value: row[key] }}
            selectedValues={[row[key]]}
            updateStatusFn={() => {}}
            disabled
          />
        );
      case 'estado_mda':
        return (
          <OrderMdaIconBtn
            filter={{ name: 'estado_mda', value: row[key] }}
            selectedValues={[row[key]]}
            updateStatusFn={() => {}}
            disabled
          />
        );
      default:
        return row[key];
    }
  };

  return (
    <>
      <Paper style={{ width: '100%', overflowX: 'auto' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '150px' }}>Actions</TableCell>
              {headers.some(head => statusKeys.includes(head.name)) && (
              <TableCell className="order-cell">Estados</TableCell>
              )}
              {headers.filter(head => !statusKeys.includes(head.name)).map(column => (
                <TableCell
                  key={column.name}
                  style={{ width: `${column.width}px`, cursor: 'pointer' }}
                  sortDirection={column.name === entregaExterna.orderBy ? entregaExterna.sortorder : 'asc'}
                  onClick={() => handleUpdate(column.name, entregaExterna.sortorder === 'asc' ? 'desc' : 'asc')}
                >
                  <TableSortLabel
                    active={entregaExterna.orderBy === column.name}
                    direction={entregaExterna.sortorder}
                    onClick={() => handleUpdate(column.name, entregaExterna.sortorder === 'asc' ? 'desc' : 'asc')}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <WhereHouseFilters
              filters={headers}
              onSearch={onSearch}
              clearFilters={() => setSelectSale([])}
              statusKeys={statusKeys}
              module="entregaExterna"
            />
            {data.length > 0 ? (
              data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell padding="checkbox" className="fixed-cell order-cell">
                    <ActionMenu key={index} order={row} handleSelectOrder={setSelectSale} />
                  </TableCell>
                  {headers.some(head => statusKeys.includes(head.name)) && (
                    <TableCell className="order-cell">
                      <div className="d-flex justify-content-center align-items-center">
                        {statusKeys.map(key => (
                          <div className="d-flex" key={key}>
                            {renderStatusIcon(key, row)}
                          </div>
                        ))}
                      </div>
                    </TableCell>
                  )}
                  {headers.filter(head => !statusKeys.includes(head.name)).map(column => (
                    <TableCell className="order-cell" key={column.name}>{row[column.name]}</TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={headers.length} className="text-center">
                  {loading ? 'Cargando...' : 'No hay entregas externas pendientes'}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>

      <ShareModal
        open={openShare}
        title={`Compartir venta ${selectSale ? selectSale?.salesorder_no : ''}`}
        handleClose={handleShareClose}
        order={selectSale || {}}
        template={appModules.SalesOrder}
        isLoading={false}
      />
    </>
  );
};

export default EntregaExternaDataTable;
