/* eslint-disable no-shadow */
/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import {
  faCommentAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CartClientMenu from './menus/CartClientMenu';
import CartSaleMenu from './menus/CartSaleMenu';
import CartDeliverMenu from './menus/CartDeliverMenu';
import SearchCustomer from '../../../Customer/components/SearchCustomer';
import { setOrderCustomer } from '../../../../redux/actions/orderActions';
import useRewardPoints from '../../../../hooks/useRewardPoints';
import UserCartInfo from '../UserCartInfo';
import { removeCustomer, setDefaultCustomer } from '../../../../redux/actions/customerActions';
import { toggleModal } from '../../../../redux/actions/modalActions';
import OrderStatusBlock from '../../../ActualOrder/components/OrderStatusBlock';

const CartHeader = () => {
  const dispatch = useDispatch();
  const authUser = useSelector(state => state.authUser);
  const cart = useSelector(state => state.cart);
  const modals = useSelector(state => state.modals);
  const customer = useSelector(state => state.customer);
  const tabs = useSelector(state => state.tabs);
  const { orderSelected, products } = cart;
  const { posType } = tabs;
  const { points, getCustomerPoints } = useRewardPoints();

  const dispatchEditOrder = account_id => dispatch(setOrderCustomer(account_id));
  const dispatchSetDefaultCustomer = defaultCustomer => dispatch(setDefaultCustomer(defaultCustomer));
  const dispatchRemoveCustomer = () => dispatch(removeCustomer());

  const togleNotesModal = () => {
    if (products.length === 0) {
      return;
    }
    dispatch(toggleModal('orderNotes'));
  };

  React.useEffect(() => {
    if (posType === 'Vender' && !!customer.selectedCustomer.crmid) {
      getCustomerPoints();
    }
  }, [posType, customer.selectedCustomer]);

  React.useEffect(() => {
    const defaultCustomer = authUser.user.account_default;
    dispatchSetDefaultCustomer(defaultCustomer);

    const { selectedCustomer } = customer;
    if (selectedCustomer.accountname === undefined) {
      dispatchRemoveCustomer();
    }
  }, []);

  return (
    <div
      className="d-flex justify-content-between align-items-center w-100"
      style={{ padding: '3px 0' }}
    >
      <div className="d-flex" style={{ gap: 5 }}>
        {
            cart.orderSelected.order && (
            <div>
              <OrderStatusBlock
                order={cart.orderSelected.order}
                tab={tabs.posTypeTab}
                searchPagination={() => {}}
                size={39}
              />
            </div>
            )
          }
        <UserCartInfo customer={customer} points={points} />
        <div style={{ minWidth: 200 }}>
          <SearchCustomer orderSelected={orderSelected} dispatchEditOrder={dispatchEditOrder} />
        </div>
        <div className="d-flex justifu-content-start align-item-center">
          <div style={{ marginRight: 5 }}>
            <CartClientMenu />
          </div>
          <CartDeliverMenu />
          <button
            type="button"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '12px',
              border: '1px solid #b8b8b8',
              borderRadius: '5px',
              height: '40px',
              cursor: 'pointer',
              backgroundColor: modals.orderNotesModalIsOpen && products.length ? '#24A1DB' : '#fff',
              borderLeft: 'none',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            onClick={togleNotesModal}
          >
            <FontAwesomeIcon color={modals.orderNotesModalIsOpen && products.length ? '#fff' : 'gray'} icon={faCommentAlt} style={{ fontSize: '16px' }} />
          </button>
        </div>
      </div>
      <CartSaleMenu />
    </div>
  ); };

export default CartHeader;
