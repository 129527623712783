/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import {
  IconButton,
  TableCell,
  TableRow,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import CustomSelect from '../../CustomInputs/components/custom-select';
import CustomDatePicker from '../../CustomInputs/components/custom-date-picker';
import OrderStatusIconBtn from '../order-status-icon-button/OrderStatusIconBtn';
import OrderFdeIconBtn from '../order-status-icon-button/OrderFdeIconBtn';
import OrderMdaIconBtn from '../order-status-icon-button/OrderMdaIconBtn';
import { useWherehouse, useWherehouseDispatch, wherehouseActionTypes } from './components/Wherehouse.context';

const WhereHouseFilters = ({
  filters = [], onSearch, clearFilters, statusKeys = [], module = '',
}) => {
  const dispatch = useWherehouseDispatch();
  const controller = new AbortController();
  const [searchParams, setSearchParams] = useState(null);
  const [inputValues, setInputValues] = useState({});
  const wherehouseState = useWherehouse();

  function handleChange(e, filter) {
    const newValues = { ...inputValues };
    switch (filter.native_type) {
      case 'picklist':
        if (e) {
          setSearchParams({
            ...searchParams,
            [filter.name]: {
              filter: filter.name,
              value: e ? e.map(option => option.value || option).toString() : '',
              operator: filter.comparator,
            },
          });
        } else {
          const newSearchParams = { ...searchParams };
          delete newSearchParams[filter.name];
          setSearchParams(newSearchParams);
        }
        newValues[filter.name] = e;
        break;
      case 'date':
      case 'datetime':
        setSearchParams({
          ...searchParams,
          [filter.name]: {
            filter: filter.name,
            value: e.date.map(date => moment(date).format('DD-MM-YYYY')).toString(),
            operator: filter.comparator,
          },
        });
        newValues[filter.name] = e.date;
        break;
      default:
        setSearchParams({
          ...searchParams,
          [filter.name]: {
            filter: filter.name,
            value: e.target.value,
            operator: filter.comparator,
          },
        });
        newValues[filter.name] = e.target.value;
        break;
    }

    setInputValues(newValues);
  }

  function renderFilters() {
    return filters.filter(head => !statusKeys.includes(head.name)).map((filter) => {
      const inputProps = {
        style: inputStyle,
        placeholder: filter.label,
        key: filter.name,
        value: inputValues[filter.name] || '',
      };

      if (filter.search_type === 'picklist' && filter.picklist_values) {
        return (
          <TableCell style={cellStyle} key={filter.name}>
            <CustomSelect
              options={filter.picklist_values}
              isMulti={filter.picklist_multiple}
              handleChange={(e) => {
                handleChange(e, filter);
              }}
              name={filter.name}
              value={inputValues[filter.name] || []}
              defaultValue={[]}
              isClearable
            />
          </TableCell>
        );
      }

      if (filter.search_type === 'date' || filter.native_type === 'datetime') {
        return (
          <TableCell style={cellStyle} key={filter.name}>
            <CustomDatePicker
              onChange={(filterName, date) => {
                if (date[0] && date[1]) {
                  handleChange({ filterName, date }, filter);
                }
              }}
              headerName={filter.name}
              range={inputValues[filter.name] || [null, null]}
              key={JSON.stringify(inputValues[filter.name])}
            />
          </TableCell>
        );
      }

      let inputType = 'text';
      if (filter.search_type === 'date' || filter.native_type === 'datetime') {
        inputType = 'date';
      } else if (filter.native_type === 'integer' || filter.native_type === 'currency') {
        inputType = 'number';
      }

      return (
        <TableCell style={cellStyle} key={filter.name}>
          <input
            {...inputProps}
            className="custom-inputs"
            style={{ width: '98%' }}
            type={inputType}
            onChange={(e) => {
              handleChange(e, filter);
            }}
          />
        </TableCell>
      );
    });
  }

  function handleSearch() {
    const searchFilters = [];

    if (searchParams && Object.keys(searchParams).length) {
      Object.values(searchParams).forEach((filter) => {
        searchFilters.push([
          filter.filter,
          filter.operator,
          filter.value,
        ]);
      });
    }
    onSearch(searchFilters);
  }

  function handleClearFilters() {
    document.dispatchEvent(new Event('clear-picklist'));
    setSearchParams(null);
    setInputValues({});
    clearFilters();
    onSearch([]);
    if (module) {
      dispatch({
        type: wherehouseActionTypes.CLEAR_FILTERS,
        payload: {
          module,
        },
      });
    }
  }

  const cellStyle = {
    width: 'auto',
    padding: '0 2px',
  };

  const inputStyle = {
    width: '100%',
    padding: '8px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    boxSizing: 'border-box',
    margin: '0 8px',
  };

  function renderStatusFilters() {
    return filters.some(head => statusKeys.includes(head.name)) && (
      <TableCell style={cellStyle}>
        <div className="d-flex justify-content-center align-items-center" style={{ gap: '2px' }}>
          {filters.some(head => head.name === 'sostatus') && (
            <OrderStatusIconBtn
              filter={filters.find(head => head.name === 'sostatus')}
              selectedValues={inputValues.sostatus || []}
              updateStatusFn={(e) => {
                handleChange(e, filters.find(head => head.name === 'sostatus'));
              }}
            />
          )}
          {filters.some(head => head.name === 'postatus') && (
            <OrderStatusIconBtn
              filter={filters.find(head => head.name === 'postatus')}
              selectedValues={inputValues.postatus || []}
              updateStatusFn={(e) => {
                handleChange(e, filters.find(head => head.name === 'postatus'));
              }}
            />
          )}
          {filters.some(head => head.name === 'estado_fde') && (
            <OrderFdeIconBtn
              filter={filters.find(head => head.name === 'estado_fde')}
              selectedValues={inputValues.estado_fde || []}
              updateStatusFn={(e) => {
                handleChange(e, filters.find(head => head.name === 'estado_fde'));
              }}
            />
          )}
          {filters.some(head => head.name === 'estado_mda') && (
            <OrderMdaIconBtn
              filter={filters.find(head => head.name === 'estado_mda')}
              selectedValues={inputValues.estado_mda || []}
              updateStatusFn={(e) => {
                handleChange(e, filters.find(head => head.name === 'estado_mda'));
              }}
            />
          )}
        </div>
      </TableCell>
    );
  }

  useEffect(() => {
    // Listen for enter key
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        handleSearch();
      }
    };
    document.addEventListener('keydown', handleKeyDown, { signal: controller.signal });
    return () => {
      controller.abort();
    };
  }, [searchParams, inputValues]);

  useEffect(() => {
    handleSearch();
  }, [wherehouseState[module]]);

  return (
    <TableRow>
      <TableCell style={cellStyle}>
        <IconButton size="small" color="primary" onClick={handleSearch}>
          <SearchIcon />
        </IconButton>
        {
          Object.keys(inputValues).length > 0 && (
            <IconButton size="small" color="secondary" onClick={handleClearFilters}>
              <CloseIcon />
            </IconButton>
          )
        }
      </TableCell>
      {renderStatusFilters()}
      {filters.length > 0 && renderFilters()}
    </TableRow>
  );
};

export default WhereHouseFilters;
