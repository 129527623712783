/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { Paper, Grid, LinearProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import OrderAPI from '../../../../../api/OrderAPI';
import PaginationNumber from '../../../../Pagination/PaginationNumber';
import ListAvailables from '../ListAvailables';
import WhereHouseDataTable from './wherehouse-data-table';
import SectionTitle from '../../section-title';
import { useWherehouse } from '../Wherehouse.context';

const EntregaVenta = () => {
  const orderApi = new OrderAPI();
  const [loading, setLoading] = useState(false);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [listSelected, setListSelected] = useState(null);
  const [actualPage, setActualPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(0);
  const authUser = useSelector(state => state.authUser.user);
  const { config: { store } } = authUser;
  const { entregaVenta } = useWherehouse();

  const addStoreFilter = (searchParams) => {
    const existStoreFilter = searchParams.findIndex(param => param[0] === 'treebesalmid');
    if (existStoreFilter === -1) {
      searchParams.push(['treebesalmid', 'c', store.name.toLowerCase()]);
    }
    return searchParams;
  };

  async function loadSectionData() {
    setLoading(true);
    const searchParams = addStoreFilter([]);
    const response = await orderApi.getListViewWithModule(listSelected.module, listSelected?.cvid, actualPage, searchParams, entregaVenta.orderBy, entregaVenta.sortorder);
    setTableHeaders(response.result.headers);
    setTableData(response.result.data);
    setCount(response.result.count);
    setPages(response.result.pages);
    setLoading(false);
  }

  const searchWherehouse = async (search = [], page = actualPage, orderBy = entregaVenta.orderBy, sortorder = entregaVenta.sortorder) => {
    setLoading(true);
    const searchParams = addStoreFilter(search);
    const response = await orderApi.getListViewWithModule(listSelected.module, listSelected.cvid, page, searchParams, orderBy, sortorder);
    setTableData(response.result.data);
    setCount(response.result.count);
    setPages(response.result.pages);
    setLoading(false);
  };

  const updatePage = (page) => {
    setActualPage(page);
    searchWherehouse([], page);
  };

  const handleListChange = (item) => {
    setListSelected(item);
    sessionStorage.setItem('entregarVentaListSelected', JSON.stringify(item));
  };

  useEffect(() => {
    if (listSelected) {
      loadSectionData();
    }
  }, [listSelected]);

  return (
    <div style={{ padding: '4px 16px' }}>
      <SectionTitle title="Entrega venta" />
      <ListAvailables moduleId={1} selectedList={listSelected} onSelect={handleListChange} />
      {loading && <LinearProgress />}
      {
          listSelected && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div style={{ padding: '5px', height: '80vh', overflow: 'auto' }}>
                    <WhereHouseDataTable headers={tableHeaders} data={tableData} onSearch={searchWherehouse} loading={loading} />
                  </div>
                </Grid>
                {/* Paginator */}
                {
                  pages > 100 && (
                    <Grid item xs={12}>
                      <Paper>
                        <div className="paginator-container">
                          <div className="w-100 px-4 d-flex flex-row flex-wrap align-items-center justify-content-between">
                            <div className="d-flex flex-row align-items-center">
                              <h4>
                                <strong className="text-secondary">{count}</strong>
                                {' '}
                                Ordenes
                              </h4>
                              {actualPage && (
                              <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                                Página
                                {' '}
                                <span className="font-weight-bold">{actualPage}</span>
                                /
                                {' '}
                                <span className="font-weight-bold">{pages}</span>
                              </span>
                              )}
                            </div>
                            <div className="d-flex flex-row py-3 align-items-center">
                              {count && (
                              <PaginationNumber
                                className="pagination-bar"
                                currentPage={actualPage}
                                totalCount={count}
                                pageSize={100}
                                onPageChange={newPage => updatePage(newPage)}
                              />
                              )}
                            </div>
                          </div>
                        </div>
                      </Paper>
                    </Grid>
                  )
                }
              </Grid>
            </>
          )
        }
    </div>
  );
};

export default EntregaVenta;
