/* eslint-disable react/prop-types */
import { faPeopleCarry, faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';

const LayoutTraspasosMenu = ({ classes, closeDashboardMenu }) => {
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  return (
    <List dense component="div" style={{ backgroundColor: '#fff', paddingLeft: 10 }}>
      <ListItem button onClick={() => setOpen(!open)}>
        <ListItemIcon>
          <FontAwesomeIcon style={{ fontSize: 20 }} icon={faPeopleCarry} />
        </ListItemIcon>
        <ListItemText inset primary="Traspasos" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      {open && <Divider />}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense component="div" style={{ backgroundColor: '#fff', paddingLeft: 8 }}>
          <ListItem
            button
            className={classes.nested}
            onClick={() => {
              closeDashboardMenu();
              history.push('/almacenes/listar/recibir-transferencia');
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon style={{ fontSize: 20 }} icon={faSignInAlt} />
            </ListItemIcon>
            <ListItemText inset primary="Recibir traspaso" />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={() => {
              closeDashboardMenu();
              history.push('/almacenes/listar/procesar-transferencia');
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon style={{ fontSize: 20 }} icon={faSignOutAlt} />
            </ListItemIcon>
            <ListItemText inset primary="Procesar traspaso" />
          </ListItem>
        </List>
      </Collapse>
    </List>
  );
};

export default LayoutTraspasosMenu;
