/* eslint-disable react/prop-types */
/* eslint-disable import/no-named-as-default */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuItem, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';
import IconWidthBG from '../../Icons/IconWidthBG/IconWidthBG';
import { getColorStatusFDE } from '../../../helpers/tools';

const OrderFdeIconBtn = ({
  filter,
  selectedValues = [],
  updateStatusFn,
  disabled = false,
}) => {
  const [statusSelected, setStatusSelected] = useState(selectedValues);
  const [anchorStatusMenu, setStatusMenu] = useState(null);
  const anchorStatusMenuIsOpen = Boolean(anchorStatusMenu);

  const selectStatus = (status) => {
    if (statusSelected.includes(status.value)) {
      const statusList = statusSelected.filter(f => f !== status.value);
      setStatusSelected(statusList);
      updateStatusFn(statusList);
      return;
    }

    const filters = [...new Set([...statusSelected, status.value])];
    setStatusSelected(filters);
    updateStatusFn(filters);
  };

  const handleClear = () => {
    setStatusSelected([]);
    updateStatusFn([]);
  };

  useEffect(() => {
    document.addEventListener('clear-picklist', handleClear);
    return () => {
      document.removeEventListener('clear-picklist', handleClear);
    };
  }, []);

  return (
    <div>
      {/* Selected status */}
      <div
        className="ml-1"
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '30px',
          cursor: 'pointer',
        }}
        role="button"
        tabIndex={0}
        onClick={(e) => {
          const { currentTarget } = e;
          setStatusMenu(currentTarget);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            const { currentTarget } = e;
            setStatusMenu(currentTarget);
          }
        }}
      >
        {statusSelected.length > 0 && !disabled
          && statusSelected.map(status => (
            <IconWidthBG
              key={status}
              primaryColor={!status ? '#ebebeb' : getColorStatusFDE(status)}
              secondaryColor="white"
              width={15}
              height={15}
              square
              rounded
            >
              <Tooltip title={filter?.label || ''}>
                <FontAwesomeIcon
                  style={{ fontSize: 7, color: !status ? 'gray' : 'white' }}
                  icon={faHandHoldingUsd}
                />
              </Tooltip>
            </IconWidthBG>
          ))}
        {(statusSelected.length === 0 || disabled) && (
          <IconWidthBG
            primaryColor={!filter.value ? '#ebebeb' : getColorStatusFDE(filter.value)}
            secondaryColor="white"
            width={27}
            height={27}
            classes="ml-2 mr-2"
            square
            rounded
          >
            <Tooltip title="Flujo de efectivo">
              <FontAwesomeIcon
                style={{ fontSize: 15, color: 'white' }}
                icon={faHandHoldingUsd}
              />
            </Tooltip>
          </IconWidthBG>
        )}
      </div>
      {/* Status list */}
      {!disabled && (
        <Menu
          id="simple-menu"
          anchorEl={anchorStatusMenu}
          open={Boolean(anchorStatusMenuIsOpen)}
          onClose={() => {
            setStatusMenu(null);
          }}
        >
          {filter.picklist_values.length > 0
          && filter.picklist_values.map(
            status => !['Sin Recibir', 'Parcialmente Recibido', 'Recibido'].includes(
              status.label,
            ) && (
            <MenuItem
              onClick={() => selectStatus(status)}
              style={
                    statusSelected.includes(status.value)
                      ? {
                        backgroundColor: getColorStatusFDE(status.value),
                        color: 'white',
                      }
                      : {}
                  }
            >
              <div className="d-flex">
                <IconWidthBG
                  key={status}
                  primaryColor={
                        !status.value
                          ? '#ebebeb'
                          : getColorStatusFDE(status.value)
                      }
                  secondaryColor="white"
                  width={27}
                  height={27}
                  classes="mr-2"
                  square
                  rounded
                >
                  <FontAwesomeIcon
                    style={{
                      fontSize: 15,
                      color: !status.value ? 'gray' : 'white',
                    }}
                    icon={faHandHoldingUsd}
                  />
                </IconWidthBG>
                <span>{status.label}</span>
              </div>
            </MenuItem>
            ),
          )}
        </Menu>
      )}
    </div>
  );
};

export default OrderFdeIconBtn;
