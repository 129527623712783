/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
/* eslint-disable array-callback-return */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
/* import Select from 'react-select'; */
import { useSelector, useDispatch } from 'react-redux';
import './ShipingAction.styles.scss';
import { Tooltip, IconButton } from '@material-ui/core';
import { toast } from 'react-toastify';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { v4 as uuidv4 } from 'uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTruck,
} from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';

import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import ReactDatePicker from 'react-datepicker';
import OrderAPI from '../../../../../../api/OrderAPI';
import ProductAPI from '../../../../../../api/ProductAPI';
import { calculateTotals } from '../../../../../../helpers/cart';
import {
  addSelectedPacking,
  setProductsById,
  addSelectedGuide,
} from '../../../../../../redux/actions/cartActions';
import { handleEditOrder, selectOrder } from '../../../../../../redux/actions/orderActions';
import { numberFormat } from '../../../../../../helpers/tools';
import GuideApi from '../../../../../../api/GuideApi';
import { loading, loaded } from '../../../../../../redux/actions/loadActions';
import { fetchAllOrderGuide } from '../../../../../../redux/actions/guideActions';
import {
  closeModal,
} from '../../../../../../redux/actions/modalActions';
import { addShipping } from '../../../../../../redux/actions/shippingActions';
import StoreShippingSection from './components/StoreShippingSection';
import QtyInput from './components/QtyInput';
import { DebounceInput } from 'react-debounce-input';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const Shipping = ({ tooltip, placeInOrderAction = false, relatedOrderId = '' }) => {
  const ProductApi = new ProductAPI();
  const guideApi = new GuideApi();
  const dispatch = useDispatch();
  const customer = useSelector(state => state.customer);
  const { shippingList } = useSelector(state => state.shipping);
  const tabs = useSelector(state => state.tabs);
  const cart = useSelector(state => state.cart);
  const { user, domain } = useSelector(state => state.authUser);
  const guide = useSelector(state => state.guide);
  const modals = useSelector(state => state.modals);
  const discount = useSelector(state => state.discount);
  const {
    cartProductsByArray,
    cartProductsById,
    total,
    subTotal,
    totalWeight,
    descuentos,
    orderSelected,
  } = cart;
  // eslint-disable-next-line camelcase
  const {
    shipping_id,
    config: { pos_no_val_inv_envio },
    omitir_fecha_entrega,
  } = user;
  const { posTypeTab } = tabs;
  const [startDate, setStartDate] = React.useState(null);
  const [note, setNote] = React.useState('');
  const { selectedCustomer, address } = customer;
  const [open, setOpen] = React.useState(false);
  const [loadin, setLoading] = React.useState(false);
  const [generate, setGenerate] = React.useState(false);
  const [shippingProviders, setShippingProviders] = React.useState([]);
  const [weight, setWeight] = React.useState(totalWeight);
  const [customProducts, setCustomProducts] = React.useState([
    ...cartProductsByArray,
  ]);
  const [stateTotal, setStateTotal] = React.useState(total);
  const [stateSubTotal, setStateSubTotal] = React.useState(subTotal);
  const [shippingSelected, setShippingSelected] = React.useState(null);
  // Packing
  const [packingList, setPackingList] = React.useState([]);
  const [selectedPacking, setSelectedPacking] = React.useState(null);

  const [storePickup, setStorePickup] = React.useState(null);

  const [isFull, setIsFull] = React.useState(false);
  const btnsRef = React.useRef(null);

  // const omitir_fecha_entrega = '0';
  //const no_validar_existencias_envio = '1';

  const noValidaExistencia = (pos_no_val_inv_envio && pos_no_val_inv_envio === '1') ? true : false;
  const omitefechaEntrega = omitir_fecha_entrega ? true : false;

  const selectShippments = (provider) => {
    if (startDate) {
      provider.fecha_sol = moment(startDate).format('YYYY-MM-DD');
      provider.hora_sol = `${startDate.getHours() < 10 ? `0${startDate.getHours()}` : startDate.getHours()}:${startDate.getMinutes() < 10 ? `0${startDate.getMinutes()}` : startDate.getMinutes()}`;
    }
    provider.carrier_logo_url = generateUrl(provider);
    if (provider.shipment_content) {
      setNote(provider.shipment_content);
    }
    setShippingSelected(provider);
      // eslint-disable-next-line no-unused-expressions
      btnsRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const getOnlyProducts = () => {
    const productsWidthRealQuantity = [];
    let onlyProducts = [];
    const productsInArray = [];
    let qtyMax = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const key in cartProductsById) {
      if (Object.hasOwnProperty.call(cartProductsById, key)) {
        productsInArray.push(cartProductsById[key]);
      }
    }

    onlyProducts = productsInArray.filter((prod) => {
      // eslint-disable-next-line no-nested-ternary
      const productid = prod.crmid
        ? prod.crmid
        : prod.productid.indexOf('x') !== -1
          ? prod.productid.split('x').pop()
          : prod.productid;

      return productid !== shipping_id;
    });

    // Guide products array
    let productsInGuide = [];

    if (
      orderSelected
      && Object.keys(orderSelected).length > 0
    ) {
      const orderGuides = guide.guides;

      orderGuides.map((g) => {
        if (g.status_shipment !== 'Cancelado') {
          const { products } = g;
          productsInGuide = [...productsInGuide, ...products];
        }
      });
    }

    const productQty = {};
    if (productsInGuide.length) {
      productsInGuide.map((p) => {
        productQty[`${p.crmid}`] = (productQty[`${p.crmid}`] || 0) + parseFloat(p.quantity);
      });
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < onlyProducts.length; i++) {
      // eslint-disable-next-line no-nested-ternary
      const productid = onlyProducts[i].crmid
        ? onlyProducts[i].crmid
        : onlyProducts[i].productid.indexOf('x') !== -1
          ? onlyProducts[i].productid.split('x').pop()
          : onlyProducts[i].productid;

      onlyProducts[i].productid = productid;
      const updatedProduct = onlyProducts[i];
      if (productsInGuide.length) {
        updatedProduct.sendQuantity = onlyProducts[i].quantity - (productQty[productid] || 0);
        updatedProduct.sendQuantityMax = onlyProducts[i].quantity - (productQty[productid] || 0);
      } else {
        updatedProduct.sendQuantity = onlyProducts[i].quantity;
        updatedProduct.sendQuantityMax = onlyProducts[i].quantity;
      }
      // Validate sendQuantityMax not be les than 0
      if (updatedProduct.sendQuantityMax < 0) {
        updatedProduct.sendQuantityMax = 0;
      }
      qtyMax += updatedProduct.sendQuantityMax;
      updatedProduct.sendQuantity = updatedProduct.sendQuantityMax - previosQtySaved(updatedProduct);
      productsWidthRealQuantity.push(updatedProduct);
    }
    setCustomProducts([...productsWidthRealQuantity]);
    // recalculate weight
    let totalProductsWeight = 0;
    productsWidthRealQuantity.map((prod) => {
      const prodWeight = numberFormat(prod.weight);
      const qty = numberFormat(prod.sendQuantity || 0);
      totalProductsWeight += prodWeight * qty;
    });

    setWeight(totalProductsWeight);
    if (qtyMax === 0 || qtyMax < 0) {
      toast.info('No quedan productos por enviar');
      setIsFull(true);
    }
  };

  const getAllPacking = async () => {
    const packings = await guideApi.getPackingList();
    const { result, success } = packings;
    if (success && result.length > 0) {
      setPackingList(result);
    } else {
      toast.info('No tienes empaques para cotizar envios');
    }
  };

  const fetchAllGuides = async () => {
    await dispatch(fetchAllOrderGuide(orderSelected.order.crmid));
  };

  React.useEffect(() => {
    if (open || modals.shippingModalIsOpen) {
      getOnlyProducts();
    }
    // eslint-disable-next-line
  }, [guide.guides, modals.shippingModalIsOpen]);

  React.useEffect(() => {
    if (open || modals.shippingModalIsOpen) {
      localStorage.setItem('rates', 'false');
      if (
        orderSelected
        && Object.keys(orderSelected).length > 0
      ) {
        fetchAllGuides();
        getOnlyProducts();
      } else {
        getOnlyProducts();
      }
      setWeight(totalWeight);
      // getClientAddress();
      getAllPacking();
      setStateTotal(total);
      setStateSubTotal(subTotal);
    }
    // eslint-disable-next-line
  }, [open, modals.shippingModalIsOpen]);

  React.useEffect(() => {
    if ((open || modals.shippingModalIsOpen) && packingList.length) {
      // eslint-disable-next-line no-nested-ternary
      packingList.sort((a, b) => (a.peso_maximo > b.peso_maximo
        ? 1
        : b.peso_maximo > a.peso_maximo
          ? -1
          : 0));
      const index = packingList.findIndex(
        pack => pack.peso_maximo >= (weight || totalWeight),
      );
      if (index !== -1) {
        setSelectedPacking(packingList[index]);
        dispatch(addSelectedPacking(packingList[index]));
      } else {
        setSelectedPacking(packingList[packingList.length - 1]);
        dispatch(addSelectedPacking(packingList[packingList.length - 1]));
      }
    }
    // eslint-disable-next-line
  }, [packingList]);

  React.useEffect(() => {
    changeStore();
  }, [storePickup]);

  const handleClickOpen = async () => {
    if (relatedOrderId) {
      await dispatch(selectOrder(relatedOrderId));
      setOpen(true);
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShippingProviders([]);
    setShippingSelected(null);
    setGenerate(false);
    setLoading(false);
    setCustomProducts([]);
    setIsFull(false);
    setSelectedPacking(null);
    setPackingList([]);
    dispatch(closeModal('shipping'));
    setNote('');
  };

  const shippingCuots = async () => {
    // Check if storePickup is has an origin direction
    if (!storePickup?.treebesdireccionenvioid || storePickup?.treebesdireccionenvioid === '0') {
      toast.error('El almacén no tiene una dirección de origen');
      return;
    }

    if (!storePickup?.___treebesdireccionenvioid_formatted___
      || !storePickup?.treebesdireccionenvioid_label) {
      toast.error('El almacén no tiene una dirección de origen');
      return;
    }

    const rateRequest = localStorage.getItem('rates');
    const canQuote = thereAreProductsToShipp();
    if (rateRequest === 'true' || !canQuote) {
      // No hay productos para cotizar
      toast.info('No hay productos para cotizar');
      return;
    }
    if (isFull) {
      return;
    }
    setLoading(true);
    const prods = [];
    const orderAPI = new OrderAPI();
    customProducts.map((prod) => {
      // eslint-disable-next-line no-nested-ternary
      const productid = prod.crmid
        ? prod.crmid
        : prod.productid.indexOf('x') !== -1
          ? prod.productid.split('x').pop()
          : prod.productid;

      if (prod.sendQuantity > 0 && (noValidaExistencia ? true : parseFloat(prod.qtyinstock) >= prod.sendQuantity)) {
        prods.push({
          productid,
          productname: prod.productname,
          sku: prod.sku,
          length: parseFloat(prod.length) || 0,
          height: parseFloat(prod.height) || 0,
          width: parseFloat(prod.width) || 0,
          weight: parseFloat(prod.weight) || 0,
          quantity: prod.sendQuantity,
          requires_shipping: '1',
          treebes_sat_prod: prod.treebes_sat_prod_label,
        });
      }
    });

    if (selectedCustomer) {
      const data = {
        source: 'TPV',
        accountid: selectedCustomer.crmid,
        // treebesalmid: fullStore && fullStore.treebesdireccionenvioid && useSendStore ? fullStore.treebesdireccionenvioid : store.crmid,
        // treebesalmid: useSendStore ? almacenEnvio.treebesdireccionenvioid : almacenSelected.treebesdireccionenvioid,
        treebesalmid: storePickup.treebesdireccionenvioid,
        products: [...prods],
        totals: {
          subtotal: stateSubTotal,
          total: stateTotal,
          discount: descuentos,
          total_fde: stateTotal,
          weight,
        },
      };
      if (selectedCustomer.treebesdireccionenvioid) {
        // Search adress
        const newAddress = address.find(
          add => add.crmid === selectedCustomer.treebesdireccionenvioid,
        );
        if (newAddress) {
          data.address = {
            treebesdireccionesid: selectedCustomer.treebesdireccionenvioid || '',
            direccion: `${newAddress.direccion}, ${newAddress.num_ext}` || '',
            relatedtoid: selectedCustomer.crmid, // newAddress.crmid || '',
            neighborhood: newAddress.neighborhood || '',
            country: newAddress.country || '',
            state: newAddress.state || '',
            city: newAddress.city || '',
            postcode: newAddress.postcode || '',
            num_ext: newAddress.num_ext || '',
            num_int: newAddress.num_int || '',
            dir_references: newAddress.dir_references || '',
            latitude: newAddress?.latitude || '',
            longitude: newAddress?.longitude || '',
            countrycode: 'MX', // selectedCustomer.countrycode || '',
            defaultaddress: `${newAddress.direccion}, ${newAddress.num_ext}` || '',
            phone: selectedCustomer.phone || newAddress.phone,
            accountname: selectedCustomer.accountname,
            countryCode: 'MX',
          };
        }
      }
      if (data.products.length === 0) {
        toast.warn('No hay productos para cotizar o no hay existencia');
        setLoading(false);
        return;
      }

      const { success, result } = await orderAPI.shippingCuots(data);
      localStorage.setItem('rates', 'false');
      if (success) {
        setShippingProviders(result);
      } else {
        toast.error('Error obteniendo los servicios de envío');
        handleClose();
      }
    } else {
      toast.warn(
        'Debes seleccionar un cliente',
      );
    }
    setLoading(false);
  };

  // Calculate total wi
  const recalculateTotalWeight = async (productId, quantity) => {
    const realQty = parseFloat(quantity);
    const editedProducts = [...customProducts];
    const index = customProducts.findIndex(
      prod => prod.productid === productId,
    );
    // Checando si puede superar la existencia
    if (!noValidaExistencia && (editedProducts[index].qtyinstock <= 0 || editedProducts[index].qtyinstock < realQty)) {
      toast.warn('No hay existencia de este producto');
      return;
    }

    if (editedProducts[index].sendQuantityMax - previosQtySaved(editedProducts[index]) < realQty) {
      toast.warn(`No puedes superar la cantidad de ${editedProducts[index].sendQuantityMax} productos`);
    } else {
      editedProducts[index].sendQuantity = realQty;
    }
    await setCustomProducts(editedProducts);
    let totalProductsWeight = 0;
    editedProducts.map((prod) => {
      const prodWeight = numberFormat(prod.weight);
      const qty = numberFormat(prod.sendQuantity || 0);

      totalProductsWeight += prodWeight * qty;
    });
    setWeight(totalProductsWeight);
    if (totalProductsWeight > 0) {
      setIsFull(false);
    } else {
      setIsFull(true);
    }

    const totals = calculateTotals(customProducts, null, discount);
    setStateTotal(totals.total);
    setStateSubTotal(totals.subTotal);
    if (editedProducts[index].sendQuantityMax >= realQty) {
      setShippingProviders([]);
    }
  };

  // Create send request for selected order
  const handleCreateShipmentRequest = async () => {
    dispatch(loading('guide'));
    const { order } = orderSelected;
    const response = await guideApi.create(
      order,
      shippingSelected,
      customer,
      selectedPacking,
      // useSendStore ? almacenEnvio.crmid : almacenSelected.crmid,
      storePickup.crmid,
    );
    const { success, result } = response;
    if (success) {
      await guideApi.createProductRequest(
        result.crmid,
        // storeDirection.crmid,
        // useSendStore ? almacenEnvio.treebesdireccionenvioid : almacenSelected.treebesdireccionenvioid,
        storePickup.treebesdireccionenvioid,
        customProducts,
      );
      await dispatch(fetchAllOrderGuide(order.crmid));
      toast.info('Solicitud de envio creada');
    }
    dispatch(loaded('guide'));
  };

  const setShippingService = async () => {
    setGenerate(true);
    if (
      orderSelected
      && Object.keys(orderSelected).length > 0
      && orderSelected.constructor === Object
    ) {
      handleCreateShipmentRequest();
    } else {
      const service = await ProductApi.get(shipping_id);
      const { result, success } = service;
      // Set selected pack service
      dispatch(addSelectedGuide(shippingSelected));
      result.unit_price = parseFloat(shippingSelected.total_amount);
      result.quantity = 1;
      result.totalPrice = parseFloat(shippingSelected.total_amount);
      result.quote_amount = parseFloat(shippingSelected.total_amount);
      result.opcion = shippingSelected.carrier;
      result.f_limite_embarque = shippingSelected.packageDate;
      result.f_entrega_estimada = shippingSelected.estimated_delivery;
      result.index = shippingSelected.index;
      result.carrier_logo_url = generateUrl(shippingSelected);
      result.id = uuidv4();
      result.carrier = shippingSelected.carrier;
      result.skydrop_rate_id = shippingSelected.skydrop_rate_id || shippingSelected.lalamove_quotationId || '';
      result.carrier_service_code = shippingSelected.carrier_service_code;
      result.shipment_method = shippingSelected.shipment_method;
      result.treebesdireccionesid = selectedCustomer.treebesdireccionenvioid;
      result.shipment_content = note || shippingSelected.shipment_content;
      if (success && shippingSelected !== null) {
        if (result.unit_price > 0) {
          await dispatch(setProductsById(result));
          result.unit_price = parseFloat(shippingSelected.total_amount);
        }
        result.products = formatProducts();
        result.packing = selectedPacking;
        // result.almacenId = useSendStore ? almacenEnvio.crmid : almacenSelected.crmid;
        result.almacenId = storePickup.crmid;
        result.fecha_sol = startDate ? moment(startDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
        result.hora_sol = startDate ? `${startDate.getHours() < 10 ? `0${startDate.getHours()}` : startDate.getHours()}:${startDate.getMinutes() < 10 ? `0${startDate.getMinutes()}` : startDate.getMinutes()}` : `${moment().format('HH')}:${moment().format('mm')}`;
        await dispatch(addShipping(result));
        if (posTypeTab !== 'Vender') {
          await dispatch(handleEditOrder());
        }
      }
    }
    setGenerate(false);
    handleClose();
    setStartDate(null);
  };

  const handleChange = (event) => {
    const selectPack = packingList.find(
      pack => pack.crmid === event.target.value,
    );
    setSelectedPacking(selectPack);
    dispatch(addSelectedPacking(selectPack));
  };

  const handleChangeQuantity = (event) => {
    const { dataset, value } = event.target;
    recalculateTotalWeight(dataset.productid, value);
  };

  const generateUrl = (provider) => {
    if (provider.carrier_logo_url && (provider.carrier_logo_url.includes('https') || provider.carrier_logo_url.includes('http'))) {
      return provider.carrier_logo_url;
    }
    return `https://${domain}/${provider.carrier_logo_url}`;
  };

  const changeStore = async () => {
    if (storePickup && storePickup.crmid) {
      const productsids = customProducts.map(p => p.crmid || p.productid);
      const response = await guideApi.checkStoreQuantities(storePickup.crmid, productsids);
      // Update products qty
      const productsQtyUpdated = customProducts.map(p => ({ ...p, qtyinstock: response?.result[p.crmid || p.productid]?.qtyinstock }));
      setCustomProducts(productsQtyUpdated);
      setShippingProviders([]);
    }
  };

  const formatProducts = () => {
    const prods = [];
    customProducts.map((prod) => {
      // eslint-disable-next-line no-nested-ternary
      const productid = prod.crmid
        ? prod.crmid
        : prod.productid.indexOf('x') !== -1
          ? prod.productid.split('x').pop()
          : prod.productid;

      if (prod.sendQuantity > 0) {
        prods.push({
          productid,
          productname: prod.productname,
          sku: prod.sku,
          length: parseFloat(prod.length) || 0,
          height: parseFloat(prod.height) || 0,
          width: parseFloat(prod.width) || 0,
          weight: parseFloat(prod.weight) || 0,
          quantity: prod.sendQuantity,
          requires_shipping: '1',
          treebes_sat_prod: prod.treebes_sat_prod_label,
        });
      }
    });

    return prods;
  };

  const previosQtySaved = (product) => {
    let actualQty = 0;
    if (shippingList.length) {
      shippingList.map((ship) => {
        ship.products.map((prod) => {
          if (prod.productid === product.crmid) {
            actualQty += prod.quantity;
          }
        });
      });
    }

    return actualQty;
  };

  const thereAreProductsToShipp = () => {
    let thereAre = false;
    customProducts.map((prod) => {
      const realAmount = prod.quantity - previosQtySaved(prod);
      if (realAmount > 0) {
        thereAre = true;
      }
    });

    return thereAre;
  };

  useEffect(() => {
    if (shippingSelected) {
      const updateShipping = { ...shippingSelected };
      if (startDate) {
        updateShipping.fecha_sol = moment(startDate).format('YYYY-MM-DD');
        updateShipping.hora_sol = `${startDate.getHours() < 10 ? `0${startDate.getHours()}` : startDate.getHours()}:${startDate.getMinutes() < 10 ? `0${startDate.getMinutes()}` : startDate.getMinutes()}`;
      }
      setShippingSelected(updateShipping);
    }
  }, [startDate]);

  return (
    <>
      <Tooltip title={tooltip} placement="left">
        <span>
          <IconButton
            id="action_control_sat"
            className="pos_menu_setDir"
            disabled={cart.products.length === 0 && !placeInOrderAction}
            onClick={handleClickOpen}
            style={{ padding: placeInOrderAction ? 0 : 'auto' }}
          >
            <FontAwesomeIcon icon={faTruck} style={{ fontSize: '0.9em' }} />
            {
              !placeInOrderAction && (<span className="font-size-10">Envíos</span>)
            }
          </IconButton>
        </span>
      </Tooltip>
      <Dialog
        fullScreen
        open={open || modals.shippingModalIsOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar style={{ position: 'relative' }}>
          <Toolbar classes={{ root: 'd-flex justify-content-between' }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" style={{ color: '#FFF' }}>
              Cotizar envíos {orderSelected && orderSelected.order ? ` de ${orderSelected.order.salesorder_no}` : ''}
            </Typography>
            <p />
          </Toolbar>
        </AppBar>
        <div className="data-container">
          <StoreShippingSection storePickup={storePickup} setStorePickup={setStorePickup} />
          <div
            className="d-flex justify-content-start align-items-center mb-4"
            style={{ paddingLeft: '1rem', paddingRight: '1rem' }}
          >
            <span className="mr-2">
              <b>Largo:</b>
              {` ${selectedPacking?.largo}`}
              {' '}
              cm
            </span>
            <span className="mr-2">
              <b>Alto:</b>
              {` ${selectedPacking?.alto}`}
              {' '}
              cm
            </span>
            <span className="mr-2">
              <b>Ancho:</b>
              {` ${selectedPacking?.ancho}`}
              {' '}
              cm
            </span>
            <span>
              <b>Peso máximo:</b>
              {` ${selectedPacking?.peso_maximo}`}
              {' '}
              kg
            </span>
          </div>
          <div
            className="d-flex justify-content-start align-items-center mb-4"
            style={{ paddingLeft: '1rem', paddingRight: '1rem' }}
          >
            <textarea className="deliver-notes w-100" value={note} onChange={e => setNote(e.target.value)} name="nota" rows="3" placeholder="Notas" />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="packing-list mx-3 mb-2 d-flex align-items-center">
              <p>
                <strong className="mr-2">Empaques: </strong>
              </p>
              <select
                className="packing-input"
                id="packing"
                style={{
                  minWidth: 100,
                  minHeight: '38px',
                  borderRadius: '5px',
                }}
                onChange={handleChange}
              >
                {packingList.length > 0
                  && packingList.map(pack => (
                    <option
                      selected={selectedPacking?.crmid === pack.crmid}
                      value={pack.crmid}
                    >
                      {pack.nombreinterno}
                    </option>
                  ))}
              </select>
            </div>
            <div className="packing-list mx-3 mb-2 d-flex align-items-center">
              <p>
                <strong className="mr-2" style={{ whiteSpace: 'nowrap' }}>Fecha entrega: </strong>
              </p>
              <ReactDatePicker
                selected={startDate}
                onChange={date => setStartDate(date)}
                dateFormat="MM/dd/yyyy h:mm aa"
                showTimeSelect
              />
            </div>
          </div>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: '100%' }}
          >
            <div>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Código</TableCell>
                    <TableCell align="right">Producto</TableCell>
                    <TableCell align="right">Cantidad</TableCell>
                    <TableCell align="right">Existencia</TableCell>
                    <TableCell align="right">Cantidad a enviar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customProducts.map(product => (
                    <TableRow key={Math.random()}>
                      <TableCell component="th" scope="row">
                        {product.productcode}
                      </TableCell>
                      <TableCell align="right">{product.productname}</TableCell>
                      <TableCell align="right">
                        {product.sendQuantityMax || 0}
                      </TableCell>
                      <TableCell align="right">{product.qtyinstock}</TableCell>
                      <TableCell align="right">
                        {/* <input
                          style={{ minWidth: 50 }}
                          className="qty-input"
                          id={`key_${product.crmid}`}
                          type="number"
                          min={0}
                          max={noValidaExistencia ? product.quantity : (product.quantity - previosQtySaved(product) || 0)}
                          data-id={product.crmid}
                          defaultValue={product.sendQuantity - previosQtySaved(product) < 0 ? 0 : product.sendQuantity - previosQtySaved(product) || 0}
                          data-productid={product.productid}
                          form="novalidatedform"
                          onChange={handleChangeQuantity}
                          disabled={noValidaExistencia ? false : (product.qtyinstock <= 0 ? true : product.productcode === 'ENVIO' || !product.sendQuantityMax)}
                          value={noValidaExistencia ? product.sendQuantity : (product.qtyinstock === 0 ? 0 : product.quantity === product.sendQuantity ? product.sendQuantity - previosQtySaved(product) : product.sendQuantity)}
                        /> */}
                        <DebounceInput
                          type="number"
                          step="1"
                          defaultValue={product.sendQuantity - previosQtySaved(product) < 0 ? 0 : product.sendQuantity - previosQtySaved(product) || 0}
                          min={0}
                          max={noValidaExistencia ? product.quantity : (product.quantity - previosQtySaved(product) || 0)}
                          id={`key_${product.crmid}`}
                          value={noValidaExistencia ? product.sendQuantity : (product.qtyinstock === 0 ? 0 : product.quantity === product.sendQuantity ? product.sendQuantity - previosQtySaved(product) : product.sendQuantity)}
                          debounceTimeout={700}
                          data-productid={product.productid}
                          onChange={handleChangeQuantity}
                          data-crmid={product.crmid || product.productid}
                          disabled={noValidaExistencia ? false : (product.qtyinstock <= 0 ? true : product.productcode === 'ENVIO' || !product.sendQuantityMax)}
                        />
                        {/* <QtyInput key={product.crmid} product={product} noValidaExistencia={noValidaExistencia} previosQtySaved={previosQtySaved} handleChangeQuantity={handleChangeQuantity} /> */}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div className="d-flex justify-content-end align-items-center my-2">
                <span>
                  Peso
                  {' '}
                  {numberFormat(weight)}
                  {' '}
                  KG
                </span>
              </div>
              {!!(shippingProviders && shippingProviders.length) && (
                <div className="my-2">
                  <h3>Cotización de envíos</h3>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Imagen</TableCell>
                        <TableCell>Tipo/Paqueteria</TableCell>
                        <TableCell align="right">Costo</TableCell>
                        <TableCell align="right">Fecha programada</TableCell>
                        <TableCell align="right">Fecha estimada</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {shippingProviders.map(provider => (
                        <TableRow
                          style={
                            shippingSelected
                            && shippingSelected.carrier_service_code
                              === provider.carrier_service_code
                              ? { backgroundColor: '#dee2e6' }
                              : (
                                provider.carrier ? {} : { display: 'none' }
                              )
                          }
                          hover
                          key={provider.carrier}
                          onClick={() => selectShippments(provider)}
                        >
                          <TableCell component="th" scope="row">
                            {provider.carrier_logo_url !== '' ? (
                              <Avatar
                                classes={{ img: 'img-responsive' }}
                                variant="square"
                                style={{
                                  borderRadius: 'none',
                                  backgroundColor: 'transparent',
                                  width: 80,
                                  height: 60,
                                }}
                                src={generateUrl(provider)}
                                sizes="medium"
                              />
                            ) : (
                              <Avatar sizes="medium">{provider.carrier}</Avatar>
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span>{provider.carrier}</span>
                              <span>{provider?.service || ''}</span>
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            {provider.total_amount}
                          </TableCell>
                          <TableCell align="right">
                            {moment(provider.packageDate).format(
                              'DD MMMM YYYY hh:mm',
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {moment(provider.estimated_delivery).format(
                              'DD MMMM YYYY',
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              )}
              <div
                ref={btnsRef}
                className="action-btn d-flex justify-content-end align-items-center my-2"
              >
                <div clasname="mx-2">
                  {loadin ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      variant="contained"
                      disabled={
                        !shippingProviders.length ? false
                          : (isFull
                        || selectedPacking === null)
                      }
                      className="mr-2"
                      onClick={() => shippingCuots()}
                    >
                      Cotizar envíos
                    </Button>
                  )}
                </div>
                <div className="mx-2">
                  {generate ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      disabled={shippingSelected === null || isFull || (!startDate && !omitefechaEntrega)}
                      variant="contained"
                      color="primary"
                      onClick={() => setShippingService()}
                    >
                      Generar
                    </Button>
                  )}
                </div>
              </div>
              {
                  !startDate && !omitefechaEntrega && (
                    <div>
                      <span style={{ color: 'red', display: 'flex', justifyContent: 'end' }}>Debe seleccionar una fecha de entrega para continuar</span>
                    </div>
                  )
                }
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

Shipping.propTypes = {
  tooltip: PropTypes.string,
};
Shipping.defaultProps = {
  tooltip: '',
};

export default Shipping;
