/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable object-curly-newline */

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingsIcon from '@material-ui/icons/Settings';
import LinkIcon from '@material-ui/icons/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faWallet,
  faBoxes,
  faCashRegister,
  faFileInvoice,
  faFileInvoiceDollar,
} from '@fortawesome/free-solid-svg-icons';
import { NavLink, useHistory } from 'react-router-dom';
import { Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import LayoutVentaMenu from './LayoutVentaMenu';
import LayoutComprasMenu from './LayoutComprasMenu';
import LayoutTraspasosMenu from './LayoutTraspasosMenu';
import { changeTab } from '../../../redux/actions/tabActions';
import { changePosTypeTabToCotizacionesAndClearCart } from '../../../redux/actions/posActions';

const LayoutMenu = ({
  theme,
  classes,
  logoname,
  organizationname,
  closeDashboardMenu,
  dashboardMenuIsDisplayed,
}) => {
  const authUser = useSelector(state => state.authUser);
  const history = useHistory();
  const dispatch = useDispatch();
  const { domain, user } = authUser;
  const { config } = user;
  const {
    deshabilitar_venta,
    deshabilitar_notas_de_compra,
    deshabilitar_almacenes,
    /* deshabilitar_citas,
    deshabilitar_clientes,
    deshabilitar_actividades, */
  } = config;
  const [open, setOpen] = React.useState(false);
  const [openAlmacenes, setOpenAlmacenes] = React.useState(false);

  return (
    <Drawer
      className={classes.drawer}
      anchor="left"
      open={dashboardMenuIsDisplayed}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={closeDashboardMenu}
    >
      <div className={classes.drawerHeader}>
        <div
          role="button"
          tabIndex={0}
          onKeyDown={closeDashboardMenu}
          style={{ cursor: 'pointer' }}
          className="w-100 d-flex align-items-center justify-content-center"
          onClick={closeDashboardMenu}
        >
          <img
            src={logoname || ''}
            alt={organizationname || ''}
            style={{ width: 'auto', height: '40px' }}
          />
        </div>
      </div>
      <Divider />
      <div className="d-flex flex-column align-items-start justify-content-between h-100">
        <List className="w-100">
          {/* <NavLink
          to="/tablero"
          onClick={() => {
            closeDashboardMenu();
          }}
        >
          <ListItem button>
            <ListItemIcon>
              <Dashboard />
            </ListItemIcon>
            <ListItemText primary="Tablero" />
          </ListItem>
        </NavLink> */}
          {deshabilitar_venta !== '1' && (
            <>
              <List component="div" style={{ backgroundColor: '#fff' }}>
                <ListItem button onClick={() => setOpen(!open)}>
                  <ListItemIcon>
                    <ShoppingCartIcon />
                  </ListItemIcon>
                  <ListItemText inset primary="Ventas" />
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                {open && <Divider />}
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List
                    dense
                    component="div"
                    style={{ backgroundColor: '#fff', paddingLeft: 10 }}
                  >
                    <ListItem
                      button
                      className={classes.nested}
                      onClick={() => {
                        history.push('/pos/vender');
                        closeDashboardMenu();
                        dispatch(changeTab('posType', 'Vender'));
                      }}
                    >
                      <ListItemIcon>
                        <FontAwesomeIcon
                          style={{ fontSize: 20 }}
                          icon={faCashRegister}
                        />
                      </ListItemIcon>
                      <ListItemText inset primary="Nueva venta" />
                    </ListItem>
                    <ListItem
                      button
                      className={classes.nested}
                      onClick={() => {
                        history.push('/pos');
                        closeDashboardMenu();
                        setTimeout(() => {
                          history.push('/pos/ventas');
                          dispatch(changeTab('posType', 'Historico'));
                        }, 100);
                      }}
                    >
                      <ListItemIcon>
                        <FontAwesomeIcon
                          style={{ fontSize: 20 }}
                          icon={faFileInvoice}
                        />
                      </ListItemIcon>
                      <ListItemText inset primary="Ver ventas" />
                    </ListItem>
                    <ListItem
                      button
                      className={classes.nested}
                      onClick={() => {
                        history.push('/pos');
                        closeDashboardMenu();
                        setTimeout(() => {
                          history.push('/pos/cotizaciones');
                          dispatch(changeTab('posType', 'Cotizaciones'));
                          dispatch(changePosTypeTabToCotizacionesAndClearCart);
                        }, 100);
                      }}
                    >
                      <ListItemIcon>
                        <FontAwesomeIcon
                          style={{ fontSize: 20 }}
                          icon={faFileInvoiceDollar}
                        />
                      </ListItemIcon>
                      <ListItemText inset primary="Cotizaciones" />
                    </ListItem>
                  </List>
                </Collapse>
              </List>
            </>
          )}

          {deshabilitar_almacenes !== '1' && (
            <>
              <ListItem button onClick={() => setOpenAlmacenes(!openAlmacenes)}>
                <ListItemIcon>
                  <FontAwesomeIcon style={{ fontSize: 20 }} icon={faBoxes} />
                </ListItemIcon>
                <ListItemText inset primary="Almacenes v2" />
                {openAlmacenes ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              {openAlmacenes && <Divider />}
              <Collapse in={openAlmacenes} timeout="auto" unmountOnExit>
                <LayoutVentaMenu
                  classes={classes}
                  closeDashboardMenu={closeDashboardMenu}
                />
                <Divider />
                <LayoutComprasMenu
                  classes={classes}
                  closeDashboardMenu={closeDashboardMenu}
                />
                <Divider />
                <LayoutTraspasosMenu
                  classes={classes}
                  closeDashboardMenu={closeDashboardMenu}
                />
              </Collapse>
            </>
          )}

          {/* {deshabilitar_clientes !== '1' && (
          <NavLink
            to="/clients"
            onClick={() => {
              closeDashboardMenu();
            }}
          >
            <ListItem button>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Clientes" />
            </ListItem>
          </NavLink>
          )} */}

          {deshabilitar_notas_de_compra !== '1' && (
            <NavLink
              to="/expenses"
              onClick={() => {
                closeDashboardMenu();
              }}
            >
              <ListItem button>
                <ListItemIcon>
                  <FontAwesomeIcon style={{ fontSize: 20 }} icon={faWallet} />
                </ListItemIcon>
                <ListItemText primary="Gastos" />
              </ListItem>
            </NavLink>
          )}

          {/* {deshabilitar_citas !== '1' && (
          <NavLink
            to="/citas"
            onClick={() => {
              closeDashboardMenu();
            }}
          >
            <ListItem button>
              <ListItemIcon>
                <EventAvailable />
              </ListItemIcon>
              <ListItemText primary="Citas" />
            </ListItem>
          </NavLink>
          )} */}

          {config.pos_only === '0' && (
            <NavLink
              to=""
              onClick={() => {
                window.open(`https://${domain}/index.php`, '_blank');
                closeDashboardMenu();
              }}
            >
              <ListItem button>
                <ListItemIcon>
                  <LinkIcon />
                </ListItemIcon>
                <ListItemText primary="Ir al sistema" />
              </ListItem>
            </NavLink>
          )}

          {/* {deshabilitar_actividades !== '1' && (
          <NavLink
            to="/actividades"
            onClick={() => {
              closeDashboardMenu();
            }}
          >
            <ListItem button>
              <ListItemIcon>
                <FontAwesomeIcon style={{ fontSize: 20 }} icon={faClipboardCheck} />
              </ListItemIcon>
              <ListItemText primary="Actividades" />
            </ListItem>
          </NavLink>
          )} */}

          {deshabilitar_almacenes !== '1' && (
            <NavLink
              to="/almacenes"
              onClick={() => {
                closeDashboardMenu();
              }}
            >
              <ListItem button>
                <ListItemIcon>
                  <FontAwesomeIcon style={{ fontSize: 20 }} icon={faBoxes} />
                </ListItemIcon>
                <ListItemText primary="Almacenes" />
              </ListItem>
            </NavLink>
          )}
        </List>
        <List className="w-100">
          <NavLink
            to="/account/profile"
            onClick={() => {
              closeDashboardMenu();
            }}
          >
            <ListItem button>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Configuración" />
            </ListItem>
          </NavLink>
        </List>
      </div>
    </Drawer>
  );
};

LayoutMenu.propTypes = {
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  logoname: PropTypes.string.isRequired,
  organizationname: PropTypes.string.isRequired,
  closeDashboardMenu: PropTypes.func.isRequired,
  dashboardMenuIsDisplayed: PropTypes.bool.isRequired,
};

export default LayoutMenu;
